interface Resolver {
  [key: string]: string
}

export const resolveToPath = (type: string, id: number) => {
  const resolver: Resolver = {
    buy: `buyrequest`,
    sell: `sellrequest`,
    absence: `absenceoccurrence`,
    late: `lateoccurrence`,
    holiday: `holidayrequest`,
    'holiday (m)': `manualholidayrequest`,
    'work from home': `workfromhomerequest`,
    lieu: `lieurequest`,
    'lieu (m)': `manuallieurequest`,
    'day off': `dayoffrequest`,
    shift: `shiftrequest`,
    birthday: `birthdayrequest`,
    course: `courserequest`,
    adjustment: `adjustmentrequest`,
    paternity: `paternityrequest`,
    maternity: `maternityrequest`,
    'night shift cover': `nightshiftcoverrequest`,
    'twilight shift cover': `twilightshiftcoverrequest`,
    other: `otherrequest`,
    'call out': 'calloutrequest',
    'on call': 'oncallrequest',
    night: 'nightrequest',
    overtime: 'overtimerequest',
    weekend: 'weekendrequest',
  }
  const baseUrl = 'myactions'
  return `/${baseUrl}/${resolver[type.toLowerCase()]}/${id}`
}
