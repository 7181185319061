export const REQUEST_TYPES = [
  'Holiday',
  'Holiday (M)',
  'Birthday',
  'Lieu',
  'Lieu Day',
  'Lieu (M)',
  'Buy',
  'Sell',
  'Shift',
  'Day Off',
  'Adjustment',
  'Work From Home',
  'Course',
  'Maternity',
  'Paternity',
  'Night Shift Cover',
  'Twilight Cover',
  'Late',
  'Absence',
  'Manual',
  'On Call',
  'Call Out',
  'Night',
  'Overtime',
  'Weekend',
]

export const onlyUnique = (value: string, index: number, array: string[]) =>
  array.indexOf(value) === index

export const setFilters = (preset?: string[]) =>
  REQUEST_TYPES.map(req => ({ event: req, show: preset ? preset.includes(req) : true }))
