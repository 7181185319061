import { Grid, Link } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { EventCustomPopup, EventPopupGrid } from './components'
import { EventTypes, PopupFields, PopupFieldsProps } from './types'
import Paragraph from '../Paragraph/index'
import StatusLabel from '../StatusLabel'
import palette from '../../../theme/palette'
import { setActiveRoute } from '../../../redux/reducers/appSettingsReducer'
import { RootStore, useAppDispatch } from '../../../redux/store'
import { StyledLink } from '../Link/components'

export function EventPopup(props: PopupFieldsProps) {
  const [currentLoc, setCurrentLoc] = useState<string>(window.location.pathname)
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()

  const onNavigateURLSwitch = (id: number, absenceType: string) => {
    let url
    const normalise = (type: string) => type.replace(/ /g, '').toLowerCase()
    switch (normalise(absenceType)) {
      case normalise(palette.type.absence.label):
        url = 'absenceoccurrence'
        navigate(`/${props.baseUrl}/${url}/${id}`, {
          state: { previousLocation: `/${props.baseUrl}`, calendar: true },
        })
        break
      case normalise(palette.type.dayOff.label):
        url = 'dayoffrequest'
        break
      case normalise(palette.type.holiday.label):
        url = 'holidayrequest'
        break
      case normalise(palette.type.holidayM.label):
        url = 'manualholidayrequest'
        break
      case normalise(palette.type.late.label):
        url = 'lateoccurrence'
        navigate(`/${props.baseUrl}/${url}/${id}`, {
          state: { previousLocation: `/${props.baseUrl}`, calendar: true },
        })
        break
      case normalise(palette.type.lieuDay.label):
      case normalise(palette.type.lieu.label):
        url = 'lieurequest'
        break
      case normalise(palette.type.lieuM.label):
        url = 'manuallieurequest'
        break
      case normalise(palette.type.workFromHome.type):
        url = 'workfromhomerequest'
        break
      case normalise(palette.type.shift.label):
        url = 'shiftrequest'
        break
      case normalise(palette.type.maternityLeave.label):
        url = 'maternityrequest'
        break
      case normalise(palette.type.paternityLeave.label):
        url = 'paternityrequest'
        break
      case normalise(palette.type.twilightCover.type):
        url = 'twilightshiftcoverrequest'
        break
      case normalise(palette.type.nightShiftCover.label):
        url = 'nightshiftcoverrequest'
        break
      case normalise(palette.type.course.label):
        url = 'courserequest'
        break
      case normalise(palette.type.adjustment.label):
        url = 'adjustmentrequest'
        break
      case normalise(palette.type.birthday.label):
        url = 'birthdayrequest'
        break
      case normalise(palette.type.night.label):
        url = 'nightrequest'
        break
      case normalise(palette.type.overtime.label):
        url = 'overtimerequest'
        break
      case normalise(palette.type.onCall.label):
        url = 'oncallrequest'
        break
      case normalise(palette.type.callOut.label):
        url = 'calloutrequest'
        break
      case normalise(palette.type.weekend.label):
        url = 'weekendrequest'
        break
      default:
        url = 'otherrequest'
    }
    const activeLocation: string = currentLoc.split('/').filter(x => x !== '')[0]
    navigate(`/${activeLocation}/${url}/${id}`, {
      state: { previousLocation: `/${activeLocation}`, calendar: true },
    })
    dispatch(setActiveRoute(`/${activeLocation}`))
  }

  const displayDrawer = (eventType: string, isFromComingUp: boolean | undefined): boolean => {
    if (eventType.toLowerCase() === 'absence' || eventType.toLowerCase() === 'late') {
      return false
    }
    if (location.pathname.toString().length === 1 || location.pathname.includes('myavailability')) {
      return true
    }
    if (location.pathname.includes('dashboard')) {
      return Boolean(isFromComingUp)
    }
    return false
  }

  const onNavigate = (
    id: number,
    eventType: string,
    eventStatus: string | undefined,
    isFromComingUp: boolean | undefined,
    requestType?: string | undefined
  ) => {
    if (currentLoc === '/dashboard' && eventType === EventTypes.ABSENCE) {
      navigate(`/myavailability/absenceoccurrence/${id}`, {
        state: { previousLocation: '/dashboard', calendar: false },
      })
      return
    }
    if (displayDrawer(eventType, isFromComingUp)) {
      props.onDrawerOpen?.(id, eventType, eventStatus, requestType)
      return
    }
    onNavigateURLSwitch(id, eventType)
  }

  const popUpStatusLabel = (pu: PopupFields) => {
    if (!props.newRequestData) {
      return pu.eventStatus
    }

    const isCancellationExists = props.newRequestData.some(
      res => res.id === pu.id && res.isCancellation
    )
    return isCancellationExists ? 'Cancellation Requested' : pu.eventStatus
  }

  const hoursText = (puHours: number, eventType: string) => {
    if (!puHours || eventType.toLowerCase() === 'late') {
      return ''
    }
    return `${String(puHours).indexOf('.') >= 0 ? puHours.toFixed(2) : puHours} Hours`
  }

  return (
    <EventCustomPopup
      open={props.fields[0].open}
      anchorEl={props.fields[0].anchorEl}
      placement={props.fields[0].placement}
    >
      <EventPopupGrid
        container
        width={`${231 * props.fields.length}px`}
        multiEvent={props.fields.length > 1}
      >
        {props.fields.map((pu, idx) => (
          <Grid
            key={pu.id}
            item
            xs={12 / props.fields.length}
            borderRight={idx < props.fields.length - 1 ? `1px solid #f1f1f1` : 'unset'}
          >
            <Grid container m={0}>
              <Grid item xs={12} mb="8px">
                {pu.eventType.toLowerCase() === 'on call' ? (
                  <Paragraph color="black" weight="bold">
                    On Call
                  </Paragraph>
                ) : (
                  <Paragraph color="black" weight="bold">
                    {`${hoursText(pu.hours, pu.eventType)} ${pu.eventType}`}
                  </Paragraph>
                )}
              </Grid>
              {pu.eventStatus?.toLowerCase() !== 'unspecified' && (
                <Grid item xs display="flex" justifyContent="center">
                  <StatusLabel status={popUpStatusLabel(pu)} />
                </Grid>
              )}
              {Boolean(pu.id) && !pu.disableEdit && !props.isAttendanceCalendar && (
                <Grid item xs={12} display="flex" justifyContent="center" mt={2}>
                  <Link component="button" variant="body2">
                    <StyledLink
                      onClick={() => {
                        onNavigate(
                          pu.id,
                          pu.eventType,
                          pu.eventStatus,
                          props.isComingUp,
                          pu.requestType
                        )
                      }}
                    >
                      View/Edit
                    </StyledLink>
                  </Link>
                </Grid>
              )}
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12} bgcolor="white" textAlign="center" mt={1}>
          <Link href="#" onClick={props.onClose} variant="body2">
            <StyledLink>Close</StyledLink>
          </Link>
        </Grid>
      </EventPopupGrid>
    </EventCustomPopup>
  )
}
