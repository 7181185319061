import React, { useCallback, useEffect, useRef, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { Box, Grid, useMediaQuery } from '@mui/material'
import { useSelector } from 'react-redux'
import { DateRange } from '@mui/x-date-pickers-pro'
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridFilterItem
} from '@mui/x-data-grid-pro'
import isDeepEqual from 'fast-deep-equal'
import Card from '../../../shared/layout/Card'
import Button from '../../../shared/UI/Button'
import { enhancementsContent } from '../../../utils/constants'
import { EnhancementStub, SubmissionPeriod, SubmitStatus } from '../../../models/enhancement'
import { SytledEnhancementsGrid, NoResultComponent } from '../../MyActionsGrid/StyledMyActionsGrid'
import { isMobileDown } from '../../../theme/deviceChecks'
import { EnhancementMobileColumns, EnhancementColumns } from './EnhancementsTableColumns'
import ButtonSmall from '../../../shared/UI/ButtonSmall'
import { MyActionsSelectedStatus } from '../../MyActionsGrid/Shared/enums'
import { resetFilters, setFilterStates } from '../../../redux/reducers/myActionsReducer'
import { RootStore, useAppDispatch } from '../../../redux/store'
import { enhancementCheckboxSettings } from '../../MyActionsGrid/myactionsFlitering'

const baseUrl = '/enhancements'
const testid = 'enhancement-summary-'
const resolveToPath = (type: string, id: number) => {
  const resolver: { [key: string]: string } = {
    'on call': 'oncallrequest',
    'call out': 'calloutrequest',
    night: 'nightrequest',
    overtime: 'overtimerequest',
    weekend: 'weekendrequest',
  }
  return `${baseUrl}/${resolver[type.toLowerCase()]}/${id}`
}

export type EnhancementsSummaryTableProps = {
  loading: boolean
  data?: EnhancementStub[]
  currentPeriod?: SubmissionPeriod
  pendingEnhancements: number
  isPeriodOpenForSubmissions: boolean
  dateRange?: DateRange<Date>
  handleSubmitToPayroll: (enhancementsIds: number[]) => void
  isAfterSubmitBy: boolean
  isFuturePeriod?: boolean
}

export function EnhancementsSummaryTable({
  loading,
  data,
  currentPeriod,
  pendingEnhancements,
  isPeriodOpenForSubmissions,
  handleSubmitToPayroll,
  dateRange,
  isAfterSubmitBy,
  isFuturePeriod,
}: EnhancementsSummaryTableProps) {
  const navigate = useNavigate()
  const mobile = useMediaQuery(isMobileDown())
  const dispatch = useAppDispatch()
  
  const activeNonEmptyTableFilters = useRef<GridFilterItem[]>([])
  const [selectedEnhancementIds, setSelectedEnhancementIds] = useState<number[]>([])

  useEffect(() => {
    setSelectedEnhancementIds([])
  }, [data])

  const handleRowClick = (type: string, id: number) => {
    if (currentPeriod?.year && currentPeriod?.period) {
      navigate(
        {
          pathname: baseUrl,
          search: `?${createSearchParams({
            year: currentPeriod?.year.toString(),
            period: currentPeriod?.period.toString(),
          })}`,
        },
        { replace: true }
      )
    }
    navigate(resolveToPath(type, id), {
      state: { previousLocation: baseUrl, params: { ...currentPeriod } },
    })
  }

  const isEnhancementSubmittable = (submitStatus: SubmitStatus) => isPeriodOpenForSubmissions 
    && [SubmitStatus.AMENDED, SubmitStatus.APPROVED].includes(submitStatus)

  const { filterStates } = useSelector((state: RootStore) => state.myActions)
  const setPendingEnhancementsSearchParams = (
    selectedDateRange: DateRange<Date>,
    directReports: boolean,
    requestStatusId: number
  ): void => {
    if (
      selectedDateRange[0] === undefined ||
      selectedDateRange[1] === undefined ||
      directReports === undefined ||
      requestStatusId === undefined
    ) {
      return
    }

    const modifiedDateRange: DateRange<Date> = [
      selectedDateRange[0],
      selectedDateRange[1],
    ]

    const enhancementDateFrom = new Date('01-01-0001')
    const enhancementDateTo = modifiedDateRange[1]
    const enhancementsRequestStatus = Number(requestStatusId)

    const newFilterStates = {
      ...filterStates,
      checkboxSettings: enhancementCheckboxSettings(),
      selectionState: {
        team: null,
        employees: null,
        status: requestStatusId,
        department: null,
        dateRange: selectedDateRange,
        directReportees: directReports,
      },
      requestParams: {
        ...filterStates.requestParams,
        directReportees: directReports!,
        dateFrom: enhancementDateFrom!,
        dateTo: enhancementDateTo!,
        requestStatusId: enhancementsRequestStatus!,
        enhancementsOnly: true,
      },
      metaData: {
        ...filterStates.metaData,
        initialised: true,
      },
    }
    dispatch(setFilterStates(newFilterStates))
  }

  const resetFilterAndShowPendingApprovals = useCallback(() => {
    dispatch(resetFilters())

    if (dateRange === undefined) {
      return
    }
    const endDate = dateRange[1]

    const allTimeDateRange: DateRange<Date> = [null, endDate]

    setPendingEnhancementsSearchParams(
      allTimeDateRange,
      false,
      MyActionsSelectedStatus.AWAITING_ACTION
    )
    navigate({
      pathname: '/myactions',
    })
  }, [navigate, dateRange, dispatch, filterStates, setPendingEnhancementsSearchParams])

  const getSubmitButtonLabel = () => {
    if (!isPeriodOpenForSubmissions) {
      if (!isFuturePeriod) {
        return enhancementsContent.submitToPayrollPeriodClosed
      }
      return enhancementsContent.viewingFuturePeriod
    }

    if ((data || []).filter(e => isEnhancementSubmittable(e.submitStatus)).length === 0) {
      return enhancementsContent.submitToPayrollNothingToSubmit
    }

    const selectedCount = selectedEnhancementIds.length
    return enhancementsContent.submitToPayrollActive(selectedCount)
  }

  const order: Partial<Record<SubmitStatus, number>> = {
    [SubmitStatus.APPROVED]: 1,
    [SubmitStatus.AMENDED]: 2,
    [SubmitStatus.SUBMITTED]: 3,
  }
  const defaultOrder = 3

  const rows = (data || [])
    .sort((a, b) => {
      const aOrder = order[a.submitStatus] ?? defaultOrder
      const bOrder = order[b.submitStatus] ?? defaultOrder

      return aOrder - bOrder
    })
    .map(item => ({ ...item, id: item.enhancementId }))

  return (
    <>
      <Card padding="20px 28px" noDivider>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonSmall
            label={
              !pendingEnhancements || isAfterSubmitBy
                ? `Approvals Pending 0`
                : `Approvals Pending ${pendingEnhancements}`
            }
            onClick={() => resetFilterAndShowPendingApprovals()}
            variant="outlined"
            color="secondary"
            disabled={!pendingEnhancements || isAfterSubmitBy}
            sx={{
              '&.MuiButtonBase-root.MuiButton-root': {
                margin: 0,
                height: '27px',
              },
            }}
          />
        </div>
        <Grid container spacing={4}>
          <Grid item xs>
            <Box
              sx={{
                marginTop: '20px',
                marginBottom: '-15px',
                maxHeight: 'calc(100vh - 20px)',
                overflowX: mobile ? 'auto' : 'hidden',
                '& .MuiDataGrid-root': {
                  minHeight: 'calc( 100vh - 475px)',
                },
              }}
              data-testid={`${testid}table`}
            >
              <SytledEnhancementsGrid
                loading={loading}
                rows={rows}
                columns={mobile ? EnhancementMobileColumns : EnhancementColumns}
                onRowClick={params =>
                  handleRowClick(params.row.enhancementType, params.row.enhancementId)
                }
                pinnedColumns={{ left: mobile ? [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'displayName'] : [] }}
                sx={{
                  cursor: 'pointer',
                  '& .MuiDataGrid-row:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                  '& .MuiDataGrid-checkboxInput': {
                    color: theme => !isPeriodOpenForSubmissions ? theme.palette.action.disabled : undefined,
                    pointerEvents: !isPeriodOpenForSubmissions ? 'none' : undefined,
                  }
                }}
                rowHeight={36.5}
                headerHeight={32}
                pageSize={rows.length}
                hideFooter
                checkboxSelection
                checkboxSelectionVisibleOnly
                selectionModel={selectedEnhancementIds}
                onSelectionModelChange={(selectionModel) => setSelectedEnhancementIds(selectionModel as number[])}
                onFilterModelChange={(filterModel) => {
                  const newActiveFilters = filterModel.items.filter(item => item.value !== '' && item.value !== undefined)
                  if (!isDeepEqual(activeNonEmptyTableFilters.current, newActiveFilters)) {
                    activeNonEmptyTableFilters.current = newActiveFilters
                    setSelectedEnhancementIds([])
                  }
                }}
                keepNonExistentRowsSelected={false}
                isRowSelectable={(params) => isEnhancementSubmittable(params.row.submitStatus)}
                components={{
                  NoRowsOverlay: NoResultComponent,
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <Button
            label={getSubmitButtonLabel()}
            type="submit"
            className="freetext"
            dataTestId="submit-to-payroll"
            onClick={() => handleSubmitToPayroll(selectedEnhancementIds)}
            disabled={selectedEnhancementIds.length === 0}
          />
        </Grid>
      </Card>
    </>
  )
}
