import DateRangeIcon from '@mui/icons-material/DateRange'
import { Grid, IconButton } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { hideDrawer, showDrawer } from '../../redux/reducers/appSettingsReducer'
import { showErrorMessage, showInfoMessage } from '../../redux/reducers/snackbarReducer'
import { setDateRange } from '../../redux/reducers/timeOffRequestsReducer'
import { RootStore, useAppDispatch } from '../../redux/store'
import { manualRequestsService } from '../../services/myActionsService'
import Drawer from '../../shared/UI/Drawer'
import InstanceStatusBanner from '../../shared/UI/InstanceStatusBanner'
import MyActionsPageHeader from '../../shared/UI/MyActionsPageHeader'
import OccurrenceSubNav from '../../shared/UI/OccurrenceSubNav'
import {
  AbsenceDetailsResponse,
  AbsenceOccurrence,
  AbsenceSickHoursPutRequest,
} from '../../types/absence'
import { AbsenceDay } from '../../types/absence-day'
import { BaseResponse } from '../../types/base-response'
import { formatDateTimeWithLocale, getLocalDateString } from '../../utils/date-utils'
import UserErrorMessage from '../../utils/errorFilter'
import { ErrorList } from '../../utils/errorList'
import AbsenceDetail from '../AbsenceDetail'
import AbsenceDrawerComponent from '../AbsenceDrawerComponent'
import AttendanceCalendar from '../AttendanceCalendar'
import SummaryLayout from '../Late/SummaryLayout'
import SummaryTwoColumn from '../Late/SummaryTwoColumn'
import Actions from './Actions'
import AddSickNote from './Actions/AddSickNote'
import CompleteAbsence from './Actions/CompleteAbsence'
import CompleteRTW from './Actions/CompleteRTW'
import OccurrenceActions from './Actions/OccurrenceActions'
import Notifications from './Notifications'
import History from './history'
import { DrawerComponentProps, SummaryProps } from './types'
import UKRtw from './Actions/RTW/UkRtw/UKRtw'
import { LocationGroups } from '../../types/location-groups'
import AddEDUK from './Actions/AddED-UK'
import AddSCA from './Actions/AddSCA'
import { getScaEdDrawerTitle } from '../../utils/app-utils'
import EditOccurrenceFactory from './EditOccurrence/LocationEditOccurrence/editOccurrenceFactory'

function Absence() {
  const [absenceOccurrence, setAbsenceOccurrence] = useState<AbsenceOccurrence>()
  const [OspHoursRemainingOnLastDay, setOspHoursRemainingOnLastDay] = useState<number>(0)
  const [summary, setSummary] = useState<Array<SummaryProps>>([])
  const [summaryLeft, setSummaryLeft] = useState<Array<SummaryProps>>([])
  const [summaryRight, setSummaryRight] = useState<Array<SummaryProps>>([])
  const [drawerComponent, setDrawerComponent] = useState<DrawerComponentProps | null>(null)
  const [view, setView] = useState('Overview')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isScaComplete, setIsScaComplete] = useState<boolean>(true)
  const [isData, setIsData] = useState<boolean>(false)
  const { refreshurlepoch } = useParams<{ refreshurlepoch: string }>()
  const { id: absenceId } = useParams<{ id: string }>()

  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const showSideDrawer = useSelector<RootStore, boolean>(
    (state: RootStore) => state.appSettings.showDrawer
  )

  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )

  const isAllowedToEditAbsence = useMemo(
    () => Boolean(userPermissions.find(x => x === 'EditAttendance')),
    [userPermissions]
  )

  const activeRoute = useSelector<RootStore, string>(
    (state: RootStore) => state.appSettings.activeRoute
  )

  const { territoryAttendanceUK } = useSelector((state: RootStore) => state.featureToggles)

  const lostHours = useCallback(
    (param: keyof AbsenceDay) => {
      let totalHoursLost = 0
      absenceOccurrence?.absenceDays.forEach(day => {
        totalHoursLost += +(day[param] || 0)
      })
      return totalHoursLost
    },
    [absenceOccurrence?.absenceDays]
  )

  const calcSickHoursRemaining = (sickDays: AbsenceDay[]) => {
    const sortedDays = sickDays.sort(
      (a: AbsenceDay, b: AbsenceDay) => new Date(a.date).getTime() - new Date(b.date).getTime()
    )
    const lastDay = sortedDays[sortedDays.length - 1]
    setOspHoursRemainingOnLastDay(Number(lastDay.sickPayHoursRemaining))
  }
  const getAbsenceData = useCallback(() => {
    manualRequestsService
      .getAbsenceOccurrence(Number(absenceId))
      .then((result: AbsenceDetailsResponse) => {
        setAbsenceOccurrence(result.absenceOccurrence)
        calcSickHoursRemaining(result.absenceOccurrence.absenceDays)
        setIsLoading(false)
        setIsData(true)
      })
      .catch(err => {
        setIsData(false)
        setIsLoading(false)
        navigate(activeRoute)
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }, [absenceId, dispatch])

  const handleExtendAbsenceSubmit = () => {
    getAbsenceData()
  }

  const handleFormSubmission = useCallback(() => {
    dispatch(hideDrawer())
    getAbsenceData()
  }, [dispatch, getAbsenceData])

  useEffect(() => {
    getAbsenceData()
  }, [getAbsenceData])

  useEffect(() => {
    if (!absenceOccurrence) {
      return
    }
    const summaryItems = [] as Array<SummaryProps>
    summaryItems.push({
      id: 1,
      title: 'Expected Return',
      value: getLocalDateString(absenceOccurrence.expectedReturn || new Date()),
    })
    summaryItems.push({ id: 2, title: 'Absent Days', value: absenceOccurrence.absenceDays?.length })
    summaryItems.push({ id: 3, title: 'Hours Lost', value: lostHours('hoursLost') })
    summaryItems.push({
      id: 4,
      title: 'Sick pay hours taken',
      value: lostHours('sickHoursToBePaid'),
    })
    summaryItems.push({
      id: 5,
      title: 'Department',
      value: absenceOccurrence.departmentName,
    })

    summaryItems.push({
      id: 7,
      title: 'Line Manager',
      value: absenceOccurrence.manager?.displayName,
    })
    summaryItems.push({
      id: 8,
      title: 'Date Submitted',
      value: formatDateTimeWithLocale(absenceOccurrence.submittedDate),
    })

    if (isAllowedToEditAbsence) {
      setSummary(summaryItems)
    } else {
      setSummaryLeft(summaryItems.slice(0, 4))
      const summaryRemainder = summaryItems.slice(4)
      summaryRemainder.push({
        id: 8,
        title: 'View Occurrence',
        value: (
          <IconButton onClick={() => setView('Calendar')}>
            <DateRangeIcon color="primary" />
          </IconButton>
        ),
      })
      setSummaryRight(summaryRemainder)
    }
  }, [absenceOccurrence, isAllowedToEditAbsence, lostHours])

  const edFormat = () => {
    switch (absenceOccurrence?.employeeResponse.locationGroupName) {
      case territoryAttendanceUK && LocationGroups.UK:
        return (
          <Grid container pr={4}>
            <AddEDUK
              absenceOccurrence={absenceOccurrence!}
              onSubmit={handleFormSubmission}
              onCancel={() => dispatch(hideDrawer())}
              setIsEdComplete={setIsScaComplete}
            />
          </Grid>
        )
      default:
        return (
          <Grid container pr={4}>
            <AddSCA
              absenceOccurrence={absenceOccurrence!}
              onSubmit={handleFormSubmission}
              onCancel={() => dispatch(hideDrawer())}
              setIsScaComplete={setIsScaComplete}
            />
          </Grid>
        )
    }
  }

  useEffect(() => {
    if (!absenceOccurrence) {
      return
    }

    calcSickHoursRemaining(absenceOccurrence.absenceDays)
  }, [absenceOccurrence?.absenceDays])

  const getRtwByLocation = () => {
    switch (absenceOccurrence?.employeeResponse.locationGroupName) {
      case territoryAttendanceUK && LocationGroups.UK:
        return (
          <UKRtw
            absenceData={absenceOccurrence!}
            onClose={handleFormSubmission}
            onCancel={() => dispatch(hideDrawer())}
            onPrint={() => undefined}
          />
        )
      default:
        return (
          <CompleteRTW
            absenceData={absenceOccurrence!}
            onClose={handleFormSubmission}
            onCancel={() => dispatch(hideDrawer())}
            onPrint={() => undefined}
          />
        )
    }
  }

  useEffect(() => {
    setTimeout(() => {
      const params = new URLSearchParams(window.location.search)
      const show = params.get('showDrawer')
      if (isData && show !== null) {
        if (show === 'addSCA' && absenceOccurrence!.hasValidSca) {
          setDrawerComponent({
            title: getScaEdDrawerTitle(
              absenceOccurrence?.employeeResponse.locationGroupName,
              territoryAttendanceUK
            ),
            component: edFormat(),
          })
          dispatch(showDrawer())
        } else if (show === 'completeRTW' && absenceOccurrence!.hasValidRtw) {
          setDrawerComponent({
            title: `Return to work - ${absenceOccurrence?.employeeResponse.displayName}`,
            component: getRtwByLocation(),
          })
          dispatch(showDrawer())
        }
      }
    }, 1000)
  }, [isData, refreshurlepoch])

  if (!absenceOccurrence) {
    return null
  }

  const applyUpdatedReturnDate = (value: any) => {
    setAbsenceOccurrence((current: any) => {
      const { expectedReturn, ...rest } = current
      return { expectedReturn: value, ...rest }
    })
  }

  const handleAction = (action: string) => {
    switch (action) {
      case 'extendAbsence': {
        dispatch(
          setDateRange([
            absenceOccurrence.absenceDays[0].date,
            absenceOccurrence.absenceDays[absenceOccurrence.absenceDays.length - 1].date,
          ])
        )
        setDrawerComponent({
          title: 'Extend Absence',
          component: (
            <Grid container pr={4}>
              <AbsenceDrawerComponent
                onAbsenceSubmit={handleExtendAbsenceSubmit}
                extendAbsenceData={absenceOccurrence}
              />
            </Grid>
          ),
        })
        break
      }
      case 'addSickNote': {
        setDrawerComponent({
          title: 'Add Sick Note',
          component: (
            <AddSickNote
              onClose={handleFormSubmission}
              onCancel={() => dispatch(hideDrawer())}
              absenceId={absenceOccurrence.id}
              dateFrom={absenceOccurrence.startDate}
              dateTo={absenceOccurrence.endDate}
            />
          ),
          isNarrow: true,
        })
        break
      }
      case 'addSCA': {
        setDrawerComponent({
          title: getScaEdDrawerTitle(
            absenceOccurrence.employeeResponse.locationGroupName,
            territoryAttendanceUK
          ),
          component: edFormat(),
        })
        break
      }
      case 'completeRTW': {
        setDrawerComponent({
          title: `Return to work - ${absenceOccurrence.employeeResponse.displayName}`,
          component: getRtwByLocation(),
        })
        break
      }
      case 'completeAbsence': {
        setDrawerComponent({
          title: `Complete Absence`,
          component: (
            <CompleteAbsence absence={absenceOccurrence} onClose={() => dispatch(hideDrawer())} />
          ),
          isNarrow: true,
        })
        break
      }
      default: {
        dispatch(hideDrawer())
        return
      }
    }
    dispatch(showDrawer())
  }


  const handleUpdateSickHoursToBePaid = (absenceDayId: number, sickHoursToBePaid: number) => {
    const contextDay = absenceOccurrence?.absenceDays.find(day => day.id === absenceDayId)
    if (!contextDay) {
      return
    }

    const existingSickHoursToBePaid = contextDay.sickHoursToBePaid ?? 0
    const sickHoursRequestedForDay = Number((sickHoursToBePaid - existingSickHoursToBePaid).toFixed(2))

    if (sickHoursToBePaid < existingSickHoursToBePaid) {
      // always allow reducing existing payment
    } else if (OspHoursRemainingOnLastDay - sickHoursRequestedForDay < 0) {
      dispatch(
        showErrorMessage(
          `Sick pay is available for this day, but your entitlement has been used elsewhere. The remaining sick hours available are ${OspHoursRemainingOnLastDay} hours`
        )
      )
      return
    }

    const request: AbsenceSickHoursPutRequest = {
      absenceId: Number(absenceId),
      absenceDayId,
      absenceReasonType: 'Sickness',
      sickHoursToBePaid,
    }
    const currentHasValidRtw = absenceOccurrence.hasValidRtw
    manualRequestsService
      .updateAbsenceSickHours(request)
      .then(() => {
        if (currentHasValidRtw) {
          dispatch(
            showInfoMessage(
              'The RTW of this absence has been invalidated. Please update the RTW and Submit'
            )
          )
        }

        manualRequestsService
          .getAbsenceOccurrence(parseInt(absenceId as string, 10))
          .then((result: AbsenceDetailsResponse) => {
            setAbsenceOccurrence(result.absenceOccurrence)
          })
          .catch(err => {
            const response: BaseResponse = err.response.data
            response.errors.forEach(error => {
              dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
            })
          })
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }
  const subNav = [
    {
      label: 'Overview',
      onClick: () => setView('Overview'),
    },
    {
      label: 'Calendar',
      onClick: () => setView('Calendar'),
    },
    {
      label: 'Edit',
      onClick: () => {
        dispatch(showDrawer())
        setDrawerComponent({
          title: 'Edit',
          component: (
            <OccurrenceActions
              absenceData={absenceOccurrence}
              onUpdate={(_field, value) => applyUpdatedReturnDate(value)}
              onSubmit={() => dispatch(hideDrawer())}
              onCancel={() => dispatch(hideDrawer())}
              getAbsenceData={getAbsenceData}
            />
          ),
          isNarrow: true,
        })
      },
    },
    {
      label: 'History',
      onClick: () => setView('History'),
    },
  ]

  return (
    <>
      <Drawer
        isOpen={showSideDrawer}
        onClose={() => {
          dispatch(hideDrawer())
        }}
        title={drawerComponent?.title || ''}
        showOptions={false}
        narrow={drawerComponent?.isNarrow}
      >
        <Grid item pl={4} pb={4} pr={4}>
          {drawerComponent?.component}
        </Grid>
      </Drawer>

      <MyActionsPageHeader title="Absence Occurrence" />
      {/* Header text and links grid */}

      <Grid container spacing={4} mb={3}>
        <Grid item xs={12} md={8}>
          {absenceOccurrence && (
            <>
              <InstanceStatusBanner
                showBackButton
                firstname={absenceOccurrence.employeeResponse?.firstName || ''}
                surname={absenceOccurrence.employeeResponse?.lastName || ''}
                status={absenceOccurrence.absenceStatus.name || ''}
              />
            </>
          )}
        </Grid>
        <Grid item xs={12} md={4} display="flex" justifyContent="flex-end" alignItems="center">
          <OccurrenceSubNav navItems={subNav} />
        </Grid>
      </Grid>
      {view === 'Calendar' && <AttendanceCalendar attendanceEvent={absenceOccurrence} />}
      {view === 'History' && <History />}
      {view === 'Overview' && (
        <Grid container spacing={4}>
          {isAllowedToEditAbsence ? (
            <>
              <Grid item xs={12} md={6} lg={6} xl={3} style={{ display: 'flex' }}>
                <Actions
                  absence={absenceOccurrence}
                  onCta={handleAction}
                  isLoading={isLoading}
                  isData={isData}
                  isScaComplete={isScaComplete}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={5} style={{ display: 'flex' }}>
                <SummaryLayout summary={summary} isLoading={isLoading} isData={isData} />
              </Grid>
              <Grid item xs={12} xl={4} style={{ display: 'flex' }}>
                <Notifications
                  isLoading={isLoading}
                  isData={isData}
                  absenceOverview={absenceOccurrence?.absenceOverview}
                  attendanceDays={absenceOccurrence?.absenceDays}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12} style={{ display: 'flex' }}>
              <SummaryTwoColumn summaryItemsLeft={summaryLeft} summaryItemsRight={summaryRight} />
            </Grid>
          )}

          <Grid item xs={12}>
            <AbsenceDetail
              isLoading={isLoading}
              isData={isData}
              absenceDays={absenceOccurrence?.absenceDays}
              absenceId={absenceOccurrence?.id}
              onDelete={deletedDay => {
                setAbsenceOccurrence((current: AbsenceOccurrence | undefined) => {
                  if (!current) {
                    return
                  }
                  const { absenceDays, ...rest } = current
                  return {
                    absenceDays: absenceDays.filter((abs: AbsenceDay) => abs.id !== deletedDay.id),
                    ...rest,
                  }
                })
              }}
              onEdit={(row: AbsenceDay) => {
                dispatch(showDrawer())
                setDrawerComponent({
                  title: 'Edit Absence Day',
                  component: (
                    <EditOccurrenceFactory
                      absenceId={absenceOccurrence.id}
                      employeeResponse={absenceOccurrence.employeeResponse}
                      absenceDay={row}
                      expectedReturn={absenceOccurrence.expectedReturn}
                      onClose={handleFormSubmission}
                    />
                  ),
                })
              }}
              handleUpdate={handleUpdateSickHoursToBePaid}
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default Absence
