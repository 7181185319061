import { format } from 'date-fns'
import { Grid, TextField, useMediaQuery } from '@mui/material'
import React, { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { hideDrawer, hideHeaderDrawer } from '../../redux/reducers/appSettingsReducer'
import { showErrorMessage, showSuccessMessage } from '../../redux/reducers/snackbarReducer'
import { RootStore, useAppDispatch } from '../../redux/store'
import { Employee, SelectOption } from '../../services/dashboardService'
import { requestsService } from '../../services/requestsService'
import AutocompleteList from '../../shared/UI/AutocompleteList'
import Button from '../../shared/UI/Button'
import CheckBox from '../../shared/UI/CheckBox'
import DatePicker from '../../shared/UI/DatePicker'
import DateTimePicker from '../../shared/UI/DateTimePicker'
import DrawerFooter from '../../shared/UI/DrawerFooter'
import DropdownMenu from '../../shared/UI/DropdownMenu'
import Paragraph from '../../shared/UI/Paragraph'
import { getSelectValuesByType } from '../../utils/app-utils'
import { REQUIRED_MESSAGE } from '../../utils/constants'
import { useForm } from '../../utils/useForm'
import {
  LateComponentValidationSchema,
  LateRequestModel,
  lateRequestModelDefaultState,
} from './model'
import UserErrorMessage from '../../utils/errorFilter'
import { BaseResponse } from '../../types/base-response'
import Alert from '../../shared/UI/Alert/Alert'
import { isMobileDown } from '../../theme/deviceChecks'
import { DateFormats } from '../../api/absence/tempTypes/generalprops'

function LateDrawerComponent() {
  const dispatch = useAppDispatch()
  const { directReports } = useSelector((state: RootStore) => state.appSettings)
  const contactTypeData = getSelectValuesByType('ContactByType')
  const latenessTypeData = getSelectValuesByType('LateType')
  const [latenessReason, setLatenessReason] = React.useState<SelectOption>()
  const [employeeSelected, setEmployeeSelected] = useState(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [disableShiftDescription, setDisableShiftDescription] = useState<boolean>(false)

  const {
    handleSubmit,
    handleChange,
    handleCheckboxChange,
    handleDateChange,
    data: formData,
    setData,
    errors,
    updateState,
  } = useForm<LateRequestModel>({
    initialValues: lateRequestModelDefaultState(),
    onSubmit: () => {
      const contactByType = contactTypeData.find(
        contact => contact.value === formData.contactByTypeId
      )
      const postBody = {
        ...formData,
        contactDateTime: format(formData.contactDateTime as Date, DateFormats.DATE_AND_TIME),
        lateDate: format(formData.lateDate as Date, DateFormats.DATE_AND_TIME),
        contactByType: contactByType?.displayValue.replaceAll(' ', '') || formData.contactByType,
      }
      setSubmitLoading(true)
      requestsService
        .createLateRequest(postBody)
        .then(data => {
          setSubmitLoading(false)
          dispatch(showSuccessMessage('Late request has been sent'))
          dispatch(hideDrawer())
          dispatch(hideHeaderDrawer())
        })
        .catch(err => {
          setSubmitLoading(false)
          const response: BaseResponse = err.response.data
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        })
    },
    validations: {
      ...LateComponentValidationSchema,
      contactDateTime: {
        custom: {
          isValid: value =>
            formData.contactByTypeId === 0 || (formData.contactByTypeId !== 0 && value !== null),
          message: REQUIRED_MESSAGE,
        },
      },
    },
  })
  const defaultDate = () => {
    const date = new Date()
    handleDateChange(date, 'lateDate')
    return date
  }
  const mobile = useMediaQuery(isMobileDown())

  const getShiftDescription = useCallback(
    (employeeId: number, shiftDate: Date) => {
      requestsService
        .getShiftDescription(
          employeeId,
          format(shiftDate, 'yyyy-MM-dd hh-mm-ss').slice(0, 10).replace(/-/g, '/')
        )
        .then(data => {
          if (data.shiftDescription) {
            setDisableShiftDescription(true)
            setData(currentData => ({
              ...currentData,
              shiftDescription: data.shiftDescription as string,
            }))
          }
        })
        .catch(err => {
          const response: BaseResponse = err.response.data
          setDisableShiftDescription(false)
          setData(currentData => ({
            ...currentData,
            shiftDescription: '9.00 - 17.30',
          }))
          if (response.status !== 404) {
            response.errors.forEach(error => {
              dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
            })
          }
        })
    },
    [dispatch, setData]
  )

  const handleEmployeeChange = (event: any, employee: Employee | null) => {
    if (employee) {
      getShiftDescription(employee.employeeId, (formData.lateDate as Date) || defaultDate())
      setEmployeeSelected(true)
    } else {
      setEmployeeSelected(false)
    }
    updateState('employeeId', employee ? employee.employeeId : null)
  }
  const handleLatenessReasonChange = (event: any, newValue: any | null) => {
    if (newValue) {
      updateState('lateTypeId', newValue ? newValue.value : null)
    }
  }

  useEffect(() => {
    if (formData.employeeId && formData.lateDate) {
      getShiftDescription(formData.employeeId, formData.lateDate as Date)
    }
  }, [formData.employeeId, formData.lateDate, getShiftDescription])

  return (
    <Grid container spacing={4} component="form" onSubmit={handleSubmit}>
      <Grid item xs={12} mt={4}>
        <Grid item xs={12} mt={0}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Paragraph weight="bold">Late Detail</Paragraph>
            </Grid>
            <Grid item xs={12} lg={6}>
              <AutocompleteList
                id="employee"
                label="Employee"
                data={directReports}
                textField="employeeName"
                value={directReports.find(emp => emp.employeeId === formData.employeeId)}
                onChange={handleEmployeeChange}
                error={!!errors.employeeId}
                helperText="Required"
                dataTestId="MyActions-Late-Name"
              />
              {!employeeSelected && (
                <Grid item mt={2}>
                  <Alert severity="info" message="Please select an Employee" />
                </Grid>
              )}
            </Grid>
            {!employeeSelected ? (
              <></>
            ) : (
              <>
                <Grid item xs={12} lg={3}>
                  <DatePicker
                    label="Date"
                    value={(formData.lateDate as Date) || defaultDate()}
                    onChange={newdate => {
                      handleDateChange(newdate, 'lateDate')
                    }}
                    error={!!errors.lateDate}
                    dataTestId="MyActions-Late-Date"
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <TextField
                    fullWidth
                    name="shiftDescription"
                    label="Shift"
                    value={formData.shiftDescription}
                    onChange={handleChange}
                    error={!!errors.shiftDescription}
                    disabled={disableShiftDescription}
                    data-testid="MyActions-Late-Shift"
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <DropdownMenu
                    label="Contact made by"
                    id="contactByType"
                    data={contactTypeData}
                    textField="displayValue"
                    valueField="value"
                    name="contactByTypeId"
                    onChange={handleChange}
                    value={formData.contactByTypeId}
                    error={!!errors.contactByType}
                    dataTestId="MyActions-Late-ContactMade"
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <AutocompleteList
                    label="Reason for lateness"
                    id="latenessReason"
                    data={getSelectValuesByType('LateType')}
                    textField="displayValue"
                    value={latenessTypeData.find(
                      reason => reason.displayValue === latenessReason?.displayValue
                    )}
                    onChange={handleLatenessReasonChange}
                    error={!!errors.lateTypeId}
                    dataTestId="MyActions-Late-Reason"
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    InputProps={{ inputProps: { min: 0 } }}
                    fullWidth
                    type="number"
                    label="Minutes lost"
                    name="lateMinutes"
                    value={formData.lateMinutes || ''}
                    onChange={handleChange}
                    error={!!errors.lateMinutes}
                    onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
                    data-testid="MyActions-Late-MinutesLost"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <CheckBox
                    defaultChecked={formData.toWorkBack}
                    label="To work back"
                    name="toWorkBack"
                    onChange={handleCheckboxChange}
                    dataTestId="MyActions-Late-WorkBack"
                  />
                </Grid>
                {(formData.contactByTypeId === 1 || formData.contactByTypeId === 2) && (
                  <>
                    <Grid item xs={12} lg={6}>
                      <DateTimePicker
                        id="late-contacted-date"
                        label="Contacted date"
                        value={formData.contactDateTime as Date}
                        onChange={newDate => {
                          handleDateChange(newDate, 'contactDateTime')
                        }}
                        error={!!errors.contactDateTime}
                        dataTestId="MyActions-Late-ContactedDateTime"
                      />
                    </Grid>
                    {!mobile && <Grid item xs={0} lg={6} />}
                  </>
                )}
                <Grid item xs={12} lg={6}>
                  <TextField
                    fullWidth
                    label="Contact Comments"
                    name="contactComments"
                    rows={5}
                    multiline
                    onChange={handleChange}
                    data-testid="MyActions-Late-ContactComments"
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    fullWidth
                    label="Comments"
                    name="comments"
                    rows={5}
                    multiline
                    onChange={handleChange}
                    data-testid="MyActions-Late-Comments"
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <DrawerFooter>
        <Button
          color="secondary"
          label="Cancel"
          onClick={() => {
            dispatch(hideHeaderDrawer())
            dispatch(hideDrawer())
          }}
          dataTestId="MyActions-Late-CancelBtn"
        />
        <Button
          label="Submit"
          type="submit"
          loading={submitLoading}
          dataTestId="MyActions-Late-SubmitBtn"
        />
      </DrawerFooter>
    </Grid>
  )
}

export default LateDrawerComponent
