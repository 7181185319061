import { Grid, TextField, useMediaQuery } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import Button from '../../shared/UI/Button'
import DrawerFooter from '../../shared/UI/DrawerFooter'
import DatePicker from '../../shared/UI/DatePicker'
import { showErrorMessage, showSuccessMessage } from '../../redux/reducers/snackbarReducer'
import { myExtrasService } from '../../services/myExtrasService'
import Paragraph from '../../shared/UI/Paragraph'
import UserErrorMessage from '../../utils/errorFilter'
import { BaseResponse } from '../../types/base-response'
import { RootStore } from '../../redux/store'
import Alert from '../../shared/UI/Alert/Alert'
import {
  resetNewRequestState,
  setAlerts,
  setDate,
  setDefaultDateRange,
  setRequestType,
  timeOffRequestsState,
} from '../../redux/reducers/timeOffRequestsReducer'
import { useBookableDateRanges } from '../../utils/Hooks/useBookableDateRanges'
import { RequestType, RequestTypeNameString } from '../../models'
import { isTabletDown } from '../../theme/deviceChecks'

type Props = {
  closeDrawer: () => void
  narrow?: boolean
  type: RequestType
  requestTypeId?: string
}

function BirthdayRequest({ closeDrawer, narrow, type, requestTypeId }: Props) {
  const [hasConflict, setHasConflict] = useState<boolean>(false)
  const [hasErrorConflict, setHasErrorConflict] = useState<boolean>(false)
  const [bankHolidayDatesSelected, setBankHolidayDates] = useState<string[] | undefined>([])
  const [comments, setComments] = useState<string>('')
  const [fieldsTouched, setFieldsTouched] = useState({ date: false, comments: false })
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [minDate, setMinDate] = useState<Date | undefined>()
  const [maxDate, setMaxDate] = useState<Date | undefined>()

  const isBelowDesktop = useMediaQuery(isTabletDown())

  const bankHolidays = useSelector<RootStore, any[] | null>(
    (state: RootStore) => state.appSettings.bankHolidays
  )
  const { selectedDate } = useSelector<RootStore, timeOffRequestsState>(
    (state: RootStore) => state.timeOff
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setRequestType(type))
    return () => {
      dispatch(resetNewRequestState())
    }
  }, [dispatch, type])

  const bookableRange = useBookableDateRanges()

  useEffect(() => {
    setMinDate(bookableRange[0])
    setMaxDate(bookableRange[1])
  }, [bookableRange])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (selectedDate === null) {
      return
    }
    setSubmitLoading(true)
    myExtrasService
      .postBirthday({ dateFrom: format(selectedDate, 'yyyy-MM-dd'), comments })
      .then(response => {
        setSubmitLoading(false)
        if (response.status === 200 && response.errors.length > 0) {
          response.errors.forEach(error => {
            dispatch(
              showErrorMessage(
                <UserErrorMessage name={error.name} description={error.description} />
              )
            )
          })
        } else {
          dispatch(showSuccessMessage('Your Birthday request has been sent'))
          dispatch(setDate(null))
          closeDrawer()
        }
      })
      .catch(err => {
        setSubmitLoading(false)
        if (err.response.data.errors[0].description.includes('a birthday request already exists')) {
          dispatch(showErrorMessage(err.response.data.errors[0].description))
        } else {
          const response: BaseResponse = err.response.data
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        }
      })
  }
  const resetConflicts = () => {
    setAlerts([])
    setHasConflict(false)
    setHasErrorConflict(false)
  }

  const handleDateChange = (newValue: Date | null) => {
    dispatch(setDefaultDateRange(undefined))
    setFieldsTouched({ ...fieldsTouched, date: true })
    dispatch(setDate(newValue))
    resetConflicts()
    setFieldsTouched({ ...fieldsTouched, date: true })
  }

  const checkBankHolidays = useCallback(() => {
    const bankHolidaysDates = bankHolidays?.map(x => new Date(x.holidayDate).toLocaleDateString())
    const checkedDay = selectedDate
    setBankHolidayDates(bankHolidaysDates?.filter(x => checkedDay?.toLocaleDateString() === x))
  }, [bankHolidays, selectedDate])

  const BHDatesToDisplay = () => bankHolidayDatesSelected?.join(' & ')

  useEffect(() => {
    if (selectedDate) {
      checkBankHolidays()
    }
  }, [checkBankHolidays, selectedDate])

  const isFormValid = () => {
    setFieldsTouched({ date: true, comments: true })
    if (selectedDate === null) {
      return false
    }
    return true
  }

  const submitButtonTestId = `${requestTypeId}-submitBtn`
  const cancelButtonTestId = `${requestTypeId}-CancelBtn`
  const commentsTestId = `${requestTypeId}-Comments`
  const dateRangeTestId = `${requestTypeId}-Date`
  return (
    <Grid
      component="form"
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (isFormValid()) {
          handleSubmit(event)
        } else {
          dispatch(showErrorMessage('Mandatory fields required.'))
        }
      }}
    >
      {bankHolidayDatesSelected && bankHolidayDatesSelected?.length > 0 && (
        <Grid item xs={12}>
          <Alert severity="info" message={`There are bank holidays on: ${BHDatesToDisplay()}`} />
        </Grid>
      )}
      <Grid container spacing={4} flexDirection="column">
        <Grid item>
          <Paragraph weight="bold">Details</Paragraph>
        </Grid>
        <Grid item xs={narrow || isBelowDesktop ? 12 : 6}>
          <DatePicker
            label="Date"
            value={selectedDate}
            onChange={handleDateChange}
            error={fieldsTouched.date && selectedDate === null}
            helperText="Required"
            maxDate={maxDate}
            minDate={minDate}
            dataTestId={dateRangeTestId}
          />
        </Grid>
        <Grid item xs={narrow || isBelowDesktop ? 12 : 6}>
          <TextField
            fullWidth
            label="Comments"
            rows={5}
            multiline
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setComments(e.target.value)
            }}
            type="text"
            value={comments}
            onBlur={() => setFieldsTouched({ ...fieldsTouched, comments: true })}
            data-testid={commentsTestId}
          />
        </Grid>
      </Grid>
      <DrawerFooter>
        <Button
          color="secondary"
          label="Cancel"
          onClick={() => {
            dispatch(setDate(null))
            closeDrawer()
          }}
          dataTestId={cancelButtonTestId}
        />
        <Button
          label="Submit"
          type="submit"
          loading={submitLoading}
          dataTestId={submitButtonTestId}
        />
      </DrawerFooter>
    </Grid>
  )
}

export default BirthdayRequest
