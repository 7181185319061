/* eslint-disable no-shadow */
import { format } from 'date-fns'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import { DateRange } from '@mui/x-date-pickers-pro'
import { AxiosError } from 'axios'
import { RootStore } from '../../redux/store'
import { EnhancementsSummaryTable } from './EnhancementSummary/EnhancementSummaryTable'
import MyActionsPageHeader from '../../shared/UI/MyActionsPageHeader'
import { enhancementTypes, enhancementsContent } from '../../utils/constants'
import { enhancementService } from '../../services/enhancementService'
import {
  EnhancementApprovalViewResponse,
  EnhancementStub,
  SubmissionPeriod,
  SubmitStatus,
} from '../../models/enhancement'
import { enhancementFilter } from './enhancementSummaryFilter'
import { EmployeeDetailsResponse } from '../../types/employee'
import { hasUserAccessToRoute } from '../../utils/app-utils'
import LoadingIndicator from '../../shared/UI/LoadingIndicator'
import { PayPeriodBanner } from './PayPeriodBanner/PayPeriodBanner'
import {
  TrendIndicator,
  TrendIndicatorProps,
  TrendIndicatorStats,
} from '../../shared/UI/TrendIndicatorCard/TrendIndicator'
import { trendIncdicatorThemes } from '../../shared/UI/TrendIndicatorCard/TrendStyles'
import { getStats } from './statsProvider'
import { showSuccessMessage } from '../../redux/reducers/snackbarReducer'
import { useDefaultErrorHandler } from '../../utils/Hooks/useDefaultErrorHandler'
import Modal from '../../shared/UI/Modal'
import { setSelectedFilter } from '../../redux/reducers/enhancementsSummaryReducer'

/*
 * Set the initial state of page data
 */
const pageURL = '/enhancements'

const defaultVals: EnhancementApprovalViewResponse = {
  enhancements: [],
  numberOfPendingEnhancements: 0,
  errors: [],
}

const defaultStats: TrendIndicatorStats = { percentage: 0, hours: 0 }

export function Enhancements() {
  const dispatch = useDispatch()
  const defaultErrorHandler = useDefaultErrorHandler()
  /*
   * Start feature flag code
   * Check if user has the permissions to view this page and the feature toggle is on
   */
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const year = searchParams.get('year')
  const period = searchParams.get('period')

  const [isFetchingEnhancements, setIsFetchingEnhancements] = useState<boolean>(false)
  const employeeDetails = useSelector<RootStore, EmployeeDetailsResponse>(
    (state: RootStore) => state.appSettings.employeeDetails
  )
  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )
  const { userSettings } = useSelector((state: RootStore) => state.appSettings)
  const { filterStates } = useSelector((state: RootStore) => state.enhancementsSummary)
  const { selection: enhancementTypeSelection } = useSelector((state: RootStore) => state.enhancementsSummary.filterStates)
  const enhancementsSettingOn = useMemo(() => userSettings?.hasEnhancements, [userSettings])
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  // Check if user has the permissions to view this page and the feature toggle is on
  useEffect(() => {
    if (!userPermissions || !userSettings) {
      return
    }

    if (
      !hasUserAccessToRoute(pageURL, userPermissions, employeeDetails.isContractor, userSettings)
    ) {
      navigate('/dashboard')
    }
  }, [employeeDetails.isContractor, userSettings, enhancementsSettingOn, navigate, userPermissions])
  /*
   * End feature flag code
   */

  const [lastResponse, setLastResponse] = useState<EnhancementApprovalViewResponse>(defaultVals)
  const filteredEnhancements = useMemo(() => enhancementFilter(lastResponse.enhancements, enhancementTypeSelection || []),
    [lastResponse.enhancements, enhancementTypeSelection])
  const [showModal, setShowModal] = useState(false)
  const [selectedEnhanementIds, setSelectedEnhancementIds] = useState<number[]>([])

  const enableModal = () => {
    setShowModal(true)
  }

  interface Stats {
    onCall?: TrendIndicatorStats
    calledOut?: TrendIndicatorStats
    overtime?: TrendIndicatorStats
    night?: TrendIndicatorStats
    weekend?: TrendIndicatorStats
    total?: TrendIndicatorStats
  }

  function isFuturePayPeriod(payPeriodStart: string): boolean {
    const currentDate = new Date()
    const startDate = new Date(payPeriodStart)
    return currentDate < startDate
  }

  const stats = useRef<Stats>({})
  const [isFuturePeriod, setIsFuturePeriod] = useState(false)

  const handleSuccessResponse = (response: EnhancementApprovalViewResponse) => {
    const { enhancements, enhancementStatsPreviousPeriod } = response
    const statsObject: Stats = {}
    if (enhancementStatsPreviousPeriod) {
      statsObject.calledOut = getStats(
        enhancements,
        enhancementStatsPreviousPeriod.calledOut.hours,
        enhancementTypes.callOut
      )
      statsObject.night = getStats(
        enhancements,
        enhancementStatsPreviousPeriod.night.hours,
        enhancementTypes.night
      )
      statsObject.onCall = getStats(
        enhancements,
        enhancementStatsPreviousPeriod.onCall.hours,
        enhancementTypes.onCall
      )
      statsObject.overtime = getStats(
        enhancements,
        enhancementStatsPreviousPeriod.overtime.hours,
        enhancementTypes.overtime
      )
      statsObject.weekend = getStats(
        enhancements,
        enhancementStatsPreviousPeriod.weekend.hours,
        enhancementTypes.weekend
      )
      statsObject.total = getStats(enhancements, enhancementStatsPreviousPeriod?.total.hours, '')
    }

    const payPeriodDetails = response.payPeriod
    if (payPeriodDetails) {
      const { previousPeriodSubmitBy } = payPeriodDetails

      const isFuturePeriod = previousPeriodSubmitBy !== undefined && new Date() < new Date(previousPeriodSubmitBy)
      setIsFuturePeriod(isFuturePeriod)
    }
    stats.current = statsObject
    setLastResponse(response)
  }

  const fetchApprovals = (submissionPeriod?: SubmissionPeriod) => {
    if (submissionPeriod?.year && submissionPeriod?.period) {
      navigate(
        {
          pathname: pageURL,
          search: `?${createSearchParams({
            year: submissionPeriod?.year.toString(),
            period: submissionPeriod?.period.toString(),
          })}`,
        },
        { replace: true }
      )
    }
    setIsFetchingEnhancements(true)
    enhancementService
      .getApprovals(submissionPeriod)
      .then(handleSuccessResponse)
      .catch(defaultErrorHandler)
      .finally(() => setIsFetchingEnhancements(false))
  }

  const submitToPayroll = async (enhancementsIds: number[]) => {
    setIsSubmitting(true)
    try {
      await enhancementService.postApprovalsSubmit(enhancementsIds)
      dispatch(showSuccessMessage(enhancementsContent.submitToPayrollSuccessMessage))
    } catch (error) {
      if (error instanceof AxiosError) {
        defaultErrorHandler(error)
      } else {
        console.error('An unexpected error occurred')
      }
    } finally {
      setIsSubmitting(false)
      fetchApprovals()
    }
  }
  const filterEnhancements = (args: string | undefined) => {
    const array = args ? [args] : []

    dispatch(setSelectedFilter(array))
  }

  const getApprovals = useCallback((submissionPeriod?: SubmissionPeriod): void => {
    fetchApprovals(submissionPeriod)
  }, [])

  useEffect(() => {
    if (year && period) {
      fetchApprovals({ year: Number(year), period: Number(period) })
    } else {
      fetchApprovals()
    }
  }, [])

  const navigateNext = () => {
    getApprovals(lastResponse.payPeriod?.nextPeriod)
  }

  const navigatePrevious = () => {
    getApprovals(lastResponse.payPeriod?.previousPeriod)
  }

  const isActive = (filterArg: string | undefined) => {
    if (filterStates.selection === undefined) return false
    if (filterArg) return filterStates.selection?.includes(filterArg)
    return filterStates.selection?.length === 0
  }

  const filters: TrendIndicatorProps[] = [
    {
      testId: 'on-call',
      theme: trendIncdicatorThemes.onCall,
      filterArg: enhancementTypes.onCall,
      stats: stats.current.onCall ?? defaultStats,
      active: isActive,
      onClick: filterEnhancements,
    },
    {
      testId: 'call-out',
      theme: trendIncdicatorThemes.callOut,
      filterArg: enhancementTypes.callOut,
      stats: stats.current.calledOut ?? defaultStats,
      active: isActive,
      onClick: filterEnhancements,
    },
    {
      testId: 'overtime',
      theme: trendIncdicatorThemes.overtime,
      filterArg: enhancementTypes.overtime,
      stats: stats.current.overtime ?? defaultStats,
      active: isActive,
      onClick: filterEnhancements,
    },
    {
      testId: 'night',
      theme: trendIncdicatorThemes.night,
      filterArg: enhancementTypes.night,
      stats: stats.current.night ?? defaultStats,
      active: isActive,
      onClick: filterEnhancements,
    },
    {
      testId: 'weekend',
      theme: trendIncdicatorThemes.weekend,
      filterArg: enhancementTypes.weekend,
      stats: stats.current.weekend ?? defaultStats,
      active: isActive,
      onClick: filterEnhancements,
    },
    {
      testId: 'total',
      theme: trendIncdicatorThemes.total,
      stats: stats.current.total ?? defaultStats,
      active: isActive,
      onClick: filterEnhancements,
    },
  ]

  const now = new Date().getTime()
  const isPeriodOpenForSubmissions =
    !!lastResponse.payPeriod?.submitEnabled &&
    !!lastResponse.payPeriod.submitBy &&
    now > new Date(lastResponse.payPeriod?.submitEnabled).getTime() &&
    now < new Date(lastResponse.payPeriod?.submitBy).getTime()

  const isAfterSubmitBy = useMemo(() => {
    if (lastResponse.payPeriod?.submitBy === undefined) {
      return false
    }
    return new Date() > new Date(lastResponse.payPeriod?.submitBy)
  }, [lastResponse.payPeriod])

  const getStatus = () => {
    const now = new Date()
    const submitByDate = new Date(lastResponse.payPeriod!.submitBy)
    const previousPeriodSubmitByDate = lastResponse.payPeriod!.previousPeriodSubmitBy
      ? new Date(lastResponse.payPeriod!.previousPeriodSubmitBy)
      : null

    if (now > submitByDate) {
      return 'closed'
    }

    if (previousPeriodSubmitByDate && previousPeriodSubmitByDate < now) {
      return 'current'
    }

    return 'next'
  }

  const renderPayPeriodBanner = () => {
    if (!lastResponse.payPeriod) return
    const { submittedOn, submitBy, dateRange, currentPeriod, previousPeriod, nextPeriod } =
      lastResponse.payPeriod

    return (
      <PayPeriodBanner
        status={getStatus()}
        statusDate={submittedOn ?? submitBy}
        dateRange={dateRange}
        currentPayPeriod={currentPeriod?.period ?? 1}
        disableNext={!nextPeriod}
        disablePrevious={!previousPeriod}
        onNext={() => {
          navigateNext()
        }}
        onPrevious={() => {
          navigatePrevious()
        }}
        submittedOn={submittedOn}
        submitBy={submitBy}
      />
    )
  }

  return (
    <>
      {(() => {
        const dataAvailable =
          userPermissions?.length > 0 &&
          enhancementsSettingOn &&
          lastResponse.payPeriod &&
          lastResponse.payPeriod?.dateRange.start &&
          lastResponse.payPeriod?.dateRange.end

          if (!dataAvailable) {
            return <LoadingIndicator show />
          }
          const submitBy = lastResponse.payPeriod?.submitBy
          const previousPeriodSubmitBy = lastResponse.payPeriod?.previousPeriodSubmitBy
          const submitByDate = submitBy ? new Date(submitBy) : null
          const submitByTime = submitByDate ? format(submitByDate, 'HH:mm') : ''
          const submitByDateFormatted = submitByDate ? format(submitByDate, 'do MMMM') : ''


        return (
          <>
            <Modal
              type="question"
              open={showModal}
              onClose={() => {
                setShowModal(false)
              }}
              onClick={async () => {
                await submitToPayroll(selectedEnhanementIds)
                setShowModal(false)
              }}
              title="Are you sure?"
              message={enhancementsContent.submitToPayrollPreSubmitMessage(
                submitByTime,
                submitByDateFormatted
              )}
              buttonLabel="Confirm"
              buttonLoading={isSubmitting}
              isDisabled={isSubmitting}
              maxWidth="sm"
            />
            <MyActionsPageHeader title={enhancementsContent.enhancements} />
            {renderPayPeriodBanner()}
            <Box
              mb="16px"
              display="flex"
              padding="6px 4px"
              sx={{
                overflowX: 'auto',
              }}
            >
              {filters.map(filter => (
                <TrendIndicator {...filter} key={filter.testId} />
              ))}
            </Box>
            <EnhancementsSummaryTable
              loading={isFetchingEnhancements}
              data={filteredEnhancements}
              handleSubmitToPayroll={(enhancementIds) => {
                enableModal()
                setSelectedEnhancementIds(enhancementIds)
              }}
              currentPeriod={lastResponse.payPeriod?.currentPeriod}
              isPeriodOpenForSubmissions={isPeriodOpenForSubmissions}
              pendingEnhancements={lastResponse.numberOfPendingEnhancements || 0}
              dateRange={[previousPeriodSubmitBy && new Date(previousPeriodSubmitBy), submitBy && new Date(submitBy)] as DateRange<Date>}
              isAfterSubmitBy={isAfterSubmitBy}
              isFuturePeriod={isFuturePeriod}
            />
          </>
        )
      })()}
    </>
  )
}
