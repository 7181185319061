import { BaseResponse } from '../../../types/base-response'

export interface NotificationLogsQuery {
  logIds?: number[]
  dateFrom?: string
  dateTo?: string
  platformIds?: number[]
  templateIds?: number[]
  eventIds?: number[]
  isSuccess?: boolean
}

export interface RegenerateHrDocumentsRequest {
  absenceIds: number[]
}

export interface ErrorDetail {
  name: string | undefined
  description: string | undefined
}

export interface RegenerateHrDocumentsResponse extends BaseResponse {
  resubmissionErrors: ErrorDetail[] | undefined
  resubmittedIds: number[] | undefined
}

export interface Dictionary<T> {
  [Key: string]: T
}

export const applicationGuids: Dictionary<string> = {
  'd5670f1b-0d5b-4ab2-83e3-54488ba53255': 'Planner365',
}

export const platformTypes: Dictionary<string> = {
  Unspecified: 'Unspecified',
  Email: 'Email',
  FileTransfer: 'File Transfer',
  InApp: 'In App',
}

export const templateTypes: Dictionary<string> = {
  Default: 'Default',
  AttendanceScaRtwAlert: 'SCA Rtw Alert',
  AttendanceScaFile: 'SCA File',
  AttendanceRtwFile: 'RTW File',
  AttendanceAbsenceDeletion: 'Absence Deletion',
  AttendanceAbsenceSickPayBreach: 'Absence Sick Pay Breach',
  HolidaysNewRequest: 'New Request',
  AttendanceAbsenceCreationScaRequired: 'Absence Creation SCA Required',
  AttendanceAbsenceCreationRtwRequired: 'Absence Creation RTW Required',
  AttendanceFdnFile: 'FDN File',
  AttendanceFdnEmail: 'FDN Email',
  HolidaysRequestUpdate: 'Request Update',
  TradingDiaryEventAssignment: 'TD Event Assignment',
  TradingDiaryEventChange: 'TD Event Change',
  HolidaysContractorUpdate: 'Holidays Contractor Update',
  MasterHeader: 'Master Header',
  MasterFooter: 'Master Footer',
  HrEdEmail: 'HR ED Email',
  AttendanceEdFile: 'ED File',
  AttendanceRtwUkFile: 'UK - RTW File',
  AttendanceFdnUkEmail: 'UK - FDN Email',
  DeadlineCallToAction: 'Deadline Call To Action'
}

export const notificationEventTypes: Dictionary<string> = {
  Unspecified: 'Unspecified',
  Absence: 'Absence',
  Late: 'Late',
  Request: 'Request',
  DeskBooking: 'Desk Booking',
  Enhancements: 'Enhancements',
  EnhancementsReviewReminder: 'Enhancements Review Reminder',
  EnhancementsPayrollSubmissionReminder: 'Enhancements Payroll Submission Reminder'
}

export interface NotificationRequestLog {
  notificationRequestLogId: number
  notificationDateTime: Date
  successfullySentDateTime?: Date | undefined
  notificationGuid: string
  applicationGuid: string
  platformType: string
  templateType: string
  notificationEventId: number
  notificationEventType: string
  isSent: boolean
}

export interface NotificationLogsResponse extends BaseResponse {
  notifications: NotificationRequestLog[]
}
