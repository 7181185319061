interface EnhancementsSummaryFilterState {
  selection: string[] | undefined
}

const enhancementsSummaryFilterState = {
  selection: [],
}

export interface EnhancementsSummaryState {
  filterStates: EnhancementsSummaryFilterState
}

export const enhancementsSummaryInitialState: EnhancementsSummaryState = {
  filterStates: enhancementsSummaryFilterState,
}

const SET_SELECTED_FILTER = 'SET_SELECTED_FILTER'

export interface SetSelectedFilterAction {
  type: typeof SET_SELECTED_FILTER
  payload: string[] | undefined
}

type enhancementsSummaryDispatchtypes = SetSelectedFilterAction

// Actions
export const setSelectedFilter = (
  selectedFilter: string[] | undefined
): SetSelectedFilterAction => ({
  type: SET_SELECTED_FILTER,
  payload: selectedFilter,
})

// Reducer
export const enhancementsSummaryReducer = (
  state: EnhancementsSummaryState = enhancementsSummaryInitialState,
  action: enhancementsSummaryDispatchtypes
): EnhancementsSummaryState => {
  switch (action.type) {
    case SET_SELECTED_FILTER:
      return { ...state, filterStates: { ...state.filterStates, selection: action.payload } }
    default:
      return state
  }
}
