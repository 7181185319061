import { Divider, Grid, Tab, Tabs } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import Card from '../shared/layout/Card'
import PageHeader from '../shared/layout/PageHeader/PageHeader'
import CardTitle from '../shared/UI/CardTitle'
import SettingsOptions from './SettingsOptions'
import SettingsThresholds from './SettingsThresholds'
import Locations from './Locations'
import { CardHeaderContainer, TabItems } from './WhoIsAway/components'
import { RootStore, useAppDispatch } from '../redux/store'
import AutocompleteList from '../shared/UI/AutocompleteList'
import { SelectOption } from '../services/dashboardService'
import { setSelectedDepartment } from '../redux/reducers/appSettingsReducer'
import SettingsRestrictions from './SettingsRestrictions'
import Alert from '../shared/UI/Alert/Alert'
import { getFilteredDepartmentsDropdownOptions } from '../utils/app-utils'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

interface SettingsTab {
  label: string
  tabInfo: object
  onClick: () => void
  value: number
}

interface SettingsTabPanel {
  index: number
  component: ReactElement
  needsDepartment: boolean
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Grid
      item
      xs={12}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Grid>
  )
}

function Settings() {
  const [tabValue, setTabValue] = useState(0)
  const [headerTitle, setHeaderTitle] = useState('Settings')
  const [selectedHeader, setSelectedHeader] = useState<number>()
  const [filteredDepartments, setFilteredDepartments] = useState<SelectOption[]>([])
  const [allowedTabs, setAllowedTabs] = useState<SettingsTab[]>()
  const [allowedTabPanels, setAllowedTabPanels] = useState<SettingsTabPanel[]>()
  const dispatch = useAppDispatch()
  const { selectedDepartment } = useSelector((state: RootStore) => state.appSettings)
  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )

  const { departments, allEmployees } = useSelector((state: RootStore) => state.appSettings)

  const userDepartment = useSelector<RootStore, SelectOption | null>(
    (state: RootStore) => state.appSettings.loggedInEmployeeDepartment
  )

  const canEdit = useMemo(
    () => ({
      settings: userPermissions.includes('EditSetting'),
      locations: userPermissions.includes('EditLocations'),
      thresholds: userPermissions.includes('EditThresholds'),
      restrictions: userPermissions.includes('EditRestrictions'),
    }),
    [userPermissions]
  )

  const canView = useMemo(
    () => ({
      settings: userPermissions.includes('ViewSettings'),
      thresholds: userPermissions.includes('ViewThresholds'),
      restrictions: userPermissions.includes('ViewRestrictions'),
    }),
    [userPermissions]
  )

  useEffect(() => {
    if (!departments || !allEmployees) {
      return
    }

    const deps = getFilteredDepartmentsDropdownOptions(departments, allEmployees, false)
    setFilteredDepartments(deps)
  }, [allEmployees, departments])

  function tabProps(index: number) {
    const tabInfo = {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      disableRipple: true,
    }
    return tabInfo
  }

  useEffect(() => {
    const tabs: SettingsTab[] = []
    const tabPanels: SettingsTabPanel[] = []
    setAllowedTabs([])
    setAllowedTabPanels([])

    if (canEdit.settings) {
      tabs.push({
        label: 'Settings',
        tabInfo: { ...tabProps(0) },
        onClick: () => {
          setSelectedHeader(0)
          setHeaderTitle('Settings')
        },
        value: 0,
      })
      tabPanels.push({
        index: 0,
        component: <SettingsOptions />,
        needsDepartment: true,
      })
    }

    if (canEdit.thresholds || canView.thresholds) {
      tabs.push({
        label: 'Thresholds',
        tabInfo: { ...tabProps(1) },
        onClick: () => {
          setSelectedHeader(1)
          setHeaderTitle('Thresholds')
        },
        value: 1,
      })
      tabPanels.push({
        index: 1,
        component: <SettingsThresholds deptId={selectedDepartment?.value || 0} />,
        needsDepartment: true,
      })
    }

    if (canEdit.restrictions || canView.restrictions) {
      tabs.push({
        label: 'Restrictions',
        tabInfo: { ...tabProps(2) },
        onClick: () => {
          setSelectedHeader(2)
          setHeaderTitle('Restrictions')
        },
        value: 2,
      })
      tabPanels.push({
        index: 2,
        component: <SettingsRestrictions deptId={selectedDepartment?.value || 0} />,
        needsDepartment: true,
      })
    }

    if (canEdit.locations) {
      tabs.push({
        label: 'Locations',
        tabInfo: { ...tabProps(3) },
        onClick: () => {
          setSelectedHeader(3)
          setHeaderTitle('Locations')
        },
        value: 3,
      })
      tabPanels.push({
        index: 3,
        component: <Locations />,
        needsDepartment: false,
      })
    }

    setAllowedTabs(tabs)
    setAllowedTabPanels(tabPanels)

    if (tabPanels.length > 0 && tabs.length > 0) {
      let selectedIndex: number
      if (!selectedHeader) {
        selectedIndex = tabs[0].value
      }

      const selectedPanel = tabPanels.find(panel => panel.index === selectedHeader || selectedIndex)
      const selectedTab = tabs.find(tab => tab.value === selectedHeader || selectedIndex)

      if (!selectedPanel || !selectedTab) {
        return
      }

      setTabValue(selectedPanel.index)
      setHeaderTitle(selectedTab.label)
    }
  }, [
    selectedDepartment?.value,
    userPermissions,
    canEdit.settings,
    canEdit.thresholds,
    canEdit.restrictions,
    canEdit.locations,
    canView.settings,
    canView.thresholds,
    canView.restrictions,
    selectedHeader,
  ])

  const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }, [])

  const handleDepartmentChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, department: SelectOption | null) => {
      dispatch(setSelectedDepartment(department))
    },
    [dispatch]
  )

  useEffect(() => {
    if (filteredDepartments.length === 0 || !userDepartment) {
      return
    }

    const [dep] = userDepartment?.displayValue === 'Unallocated' ? filteredDepartments : [undefined]

    dispatch(
      setSelectedDepartment({
        value: dep?.value ?? userDepartment.value,
        displayValue: dep?.displayValue ?? userDepartment.displayValue,
      } as SelectOption)
    )
  }, [
    dispatch,
    userDepartment,
    userPermissions,
    filteredDepartments,
    userDepartment?.value,
    userDepartment?.displayValue,
  ])

  useEffect(() => {
    if (!selectedDepartment && userDepartment) {
      const department = filteredDepartments.find(x => x.value === userDepartment.value)
      if (department) {
        dispatch(setSelectedDepartment(department))
      }
    }
  }, [filteredDepartments, dispatch, selectedDepartment, userDepartment])

  const canChangeDepartmentFilter = useCallback((): boolean => {
    if (!allowedTabs || filteredDepartments.length <= 1) {
      return false
    }

    const tabToCheck = allowedTabs.find(x => x.value === tabValue)

    switch (tabToCheck?.label) {
      case 'Settings':
        return canEdit.settings || canView.settings
      case 'Thresholds':
        return canEdit.thresholds || canView.thresholds
      case 'Restrictions':
        return canEdit.restrictions || canView.restrictions
      default:
        return false
    }
  }, [
    allowedTabs,
    filteredDepartments.length,
    tabValue,
    canEdit.settings,
    canEdit.thresholds,
    canEdit.restrictions,
    canView.settings,
    canView.thresholds,
    canView.restrictions,
  ])

  return (
    <>
      <PageHeader title="Settings" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card>
            <CardHeaderContainer>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  {headerTitle !== 'Locations' && (
                    <AutocompleteList
                      id="settings-department-dropdown"
                      label="Department"
                      data={filteredDepartments}
                      textField="displayValue"
                      value={selectedDepartment}
                      onChange={handleDepartmentChange}
                      disabled={!canChangeDepartmentFilter()}
                      isOptionEqualToValue={(option, value) => option?.value === value?.value}
                      dataTestId="Settings-DepartmentDDL"
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={8}
                  display="flex"
                  justifyContent={{ xs: 'center', md: 'flex-end' }}
                  alignSelf="center"
                >
                  <TabItems>
                    <Tabs
                      value={tabValue}
                      onChange={handleTabChange}
                      aria-label="Settings Tabs"
                      variant="scrollable"
                      scrollButtons="auto"
                      allowScrollButtonsMobile
                    >
                      {allowedTabs?.map(allowedTab => (
                        <Tab
                          label={allowedTab.label}
                          {...allowedTab.tabInfo}
                          onClick={allowedTab.onClick}
                          value={allowedTab.value}
                          data-testid={`Settings-${allowedTab.label}Tab`}
                        />
                      ))}
                    </Tabs>
                  </TabItems>
                </Grid>
              </Grid>
            </CardHeaderContainer>
            <Divider />
            <Grid item xs={12} marginTop={4}>
              <Grid item mb={4}>
                <CardTitle title={headerTitle} />
              </Grid>
              <Grid container spacing={4}>
                {allowedTabPanels?.map(allowedTabPanel => (
                  <TabPanel value={tabValue} index={allowedTabPanel.index}>
                    {selectedDepartment || !allowedTabPanel.needsDepartment ? (
                      allowedTabPanel.component
                    ) : (
                      <Alert severity="info" message="Please select a department" />
                    )}
                  </TabPanel>
                ))}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default Settings
