import { format } from 'date-fns'
import { RequestParams } from '../models/enhancement/enhancement-request-params'
import { httpServiceV2 } from './httpServiceV2'
import { ManagerRequests } from '../types/manager-requests'
import {
  BulkSubmitRequest,
  BulkSubmitResponse,
  EnhancementGetResponse,
  EnhancementIdResponse,
  EnhancementPostBody,
  EnhancementPutBody,
  CalculateCalloutPaidHoursPostBody,
  CalculateCalloutPaidHoursResponse,
} from '../models/enhancement'
import {
  DefaultHoursParams,
  DefaultHoursResponse,
} from '../models/enhancement/default-department-hours'

const requestsBaseURL = 'v2/MyActions'
export const v2MyActionsService = {
  getRequests: async (params: RequestParams): Promise<ManagerRequests> => {
    let requestURL =
      `${requestsBaseURL}/requests` +
      `?DirectReportees=${params.directReportees}` +
      `&DateFrom=${format(params.dateFrom, 'yyyy-MM-dd')}` +
      `&DateTo=${format(params.dateTo, 'yyyy-MM-dd')}` +
      `${params.departmentId ? `&DepartmentId=${params.departmentId}` : ``}` +
      `${params.departmentTeamId ? `&DepartmentTeamId=${params.departmentTeamId}` : ``}` +
      `&RequestStatusId=${params.requestStatusId >= 0 ? params.requestStatusId : 6}` +
      `&EnhancementsOnly=${params.enhancementsOnly}`
    if (params?.employeeIds) {
      params.employeeIds.forEach(id => {
        requestURL += `&EmployeeIds=${id}`
      })
    }
    return httpServiceV2.get(requestURL)
  },

  getDefaultDepartmentOnCallHours: async (
    body: DefaultHoursParams
  ): Promise<DefaultHoursResponse> => {
    let queryString = `departmentId=${body.departmentId}&${body.dates
      .map(date => `dates=${date.toString().split('T')[0]}`)
      .join('&')}`

    if (body.teamId !== undefined) {
      queryString = `${queryString}&teamId=${body.teamId}`
    }

    const requestURL = `${requestsBaseURL}/departmentDefaultHours?${queryString}`
    return httpServiceV2.get(requestURL)
  },

  postEnhancement: async (body: EnhancementPostBody): Promise<EnhancementIdResponse> =>
    httpServiceV2.post(`${requestsBaseURL}/request/enhancement`, body),

  postEnhancementManual: async (body: EnhancementPostBody): Promise<EnhancementIdResponse> =>
    httpServiceV2.post(`${requestsBaseURL}/request/manual/enhancement`, body),

  putEnhancement: async (body: EnhancementPutBody): Promise<EnhancementIdResponse> =>
    httpServiceV2.put(`${requestsBaseURL}/request/enhancement`, body),

  putEnhancementManual: async (body: EnhancementPutBody): Promise<EnhancementIdResponse> =>
    httpServiceV2.put(`${requestsBaseURL}/request/manual/enhancement`, body),

  deleteEnhancement: async (enhancementId: number): Promise<EnhancementIdResponse> =>
    httpServiceV2.del(`${requestsBaseURL}/enhancement/${enhancementId}`),

  deleteEnhancementForManager: async (enhancementId: number): Promise<EnhancementIdResponse> =>
    httpServiceV2.del(`${requestsBaseURL}/enhancement/manager/${enhancementId}`),

  getEnhancement: async (enhancementId: number): Promise<EnhancementGetResponse> =>
    httpServiceV2.get(`${requestsBaseURL}/enhancements/${enhancementId}`),

  getEnhancementForManager: async (enhancementId: number): Promise<EnhancementGetResponse> =>
    httpServiceV2.get(`${requestsBaseURL}/enhancement/manager/${enhancementId}`),

  postBulkSubmitApprove: async (body: BulkSubmitRequest): Promise<BulkSubmitResponse> =>
    httpServiceV2.post(`${requestsBaseURL}/request/bulk/submit/approve`, body),

  postBulkSubmitDecline: async (body: BulkSubmitRequest): Promise<BulkSubmitResponse> =>
    httpServiceV2.post(`${requestsBaseURL}/request/bulk/submit/decline`, body),

  postCalculateCalloutPaidHours: async (
    body: CalculateCalloutPaidHoursPostBody
  ): Promise<CalculateCalloutPaidHoursResponse> =>
    httpServiceV2.post(`${requestsBaseURL}/enhancement/callout/calculate`, body),
}
