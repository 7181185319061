import { Grid, TextField } from '@mui/material'
import { SwitchContainer } from '../components'
import CheckBox from '../../../shared/UI/CheckBox'
import DropdownMenu from '../../../shared/UI/DropdownMenu'
import { getSelectValuesByType } from '../../../utils/app-utils'
import DateTimePicker from '../../../shared/UI/DateTimePicker'
import DatePicker from '../../../shared/UI/DatePicker'
import DateRangePicker from '../../../shared/UI/DateRangePicker'
import { isTabletDown } from '../../../theme/deviceChecks'
import { AbsenceFormProps } from './types'
import PartDayAbsenceForm from './partDayAbsenceForm'
import CovidIsoPayAlert from '../../Absence/Actions/CovidIsoPayAlert'

function AbsenceForm(props: AbsenceFormProps) {
  return (
    <>
      <Grid item xs={12}>
        <DateRangePicker
          value={props.dateRange}
          arrange={isTabletDown() ? 'Stack' : 'Horizontal'}
          onChange={props.handleDateRangeChange}
          dataTestId="MyActions-Absence-"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          fullWidth
          label="Shift"
          name="shiftDescription"
          value={props.absenceData.shiftDescription}
          onChange={props.handleChange}
          disabled={props.isDisableShiftDescription}
          onBlur={props.onShiftStringBlur}
          data-testid="MyActions-Absence-shift"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <DatePicker
          label="Expected Return"
          value={props.absenceData.expectedReturn}
          onChange={newValue => {
            if (!newValue) {
              return
            }
            props.setData(absence => ({ ...absence, expectedReturn: new Date(newValue) }))
          }}
          dataTestId="MyActions-Absence-ExpectedReturn"
        />
      </Grid>

      <PartDayAbsenceForm
        isOpen={props.absenceData.partDayAbsence}
        setIsOpen={props.setOpen}
        popperText={props.popperText}
        shiftDescription={props.absenceData.shiftDescription}
        dateRange={props.dateRange}
        absentFrom={props.absenceData.absentFrom}
        hasHadLunch={props.absenceData.hasHadLunch}
        toWorkBack={props.absenceData.toWorkBack}
        handleDateChange={props.handleDateChange}
        handleCheckboxChange={props.handleCheckboxChange}
        setAbsentFromDate={props.setAbsentFromDate}
      />

      <Grid item xs={12} md={6}>
        <DropdownMenu
          label="Absence Type"
          id="absenceType"
          data={props.absenceReasonTypeList}
          textField="displayValue"
          valueField="value"
          name="absenceCategoryId"
          value={props.absenceData.absenceCategoryId}
          onChange={e => {
            const filteredList = props.absenceReasonTypeList.find(
              item => `${item.value}` === `${e.target.value}`
            )
            if (filteredList) {
              props.handleChange(e)
              props.setCurrentTypeAssociatedValueId(filteredList?.value || 0)
            }
          }}
          error={props.validationErrors.absenceTypeId}
          helperText="Required"
          disabled={Boolean(props.extendAbsenceData)}
          dataTestId="MyActions-Absence-AbsenceType"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DropdownMenu
          label="Reason for absence"
          id="absenceReason"
          data={props.absenceTypesList.filter(
            absenceType => absenceType.associatedValue === props.absenceData.absenceCategoryId
          )}
          textField="displayValue"
          valueField="value"
          name="absenceTypeId"
          value={props.absenceData.absenceTypeId}
          onChange={props.handleChange}
          error={props.validationErrors.absenceTypeId}
          helperText="Required"
          disabled={Boolean(props.extendAbsenceData) || !props.currentTypeAssociatedValueId}
          dataTestId="MyActions-Absence-ReasonAbsence"
        />
      </Grid>
      <CovidIsoPayAlert
        absenceCategoryId={props.absenceData.absenceCategoryId}
        absenceTypeId={props.absenceData.absenceTypeId}
        covidIsolationIndicator={props.absenceData.covidIsolationIndicator}
      />
      <Grid item xs={12} md={12}>
        <DropdownMenu
          label="Contact made by"
          id="year-select"
          data={getSelectValuesByType('ContactByType')}
          textField="displayValue"
          valueField="value"
          name="contactedByTypeId"
          value={props.absenceData.contactedByTypeId}
          onChange={props.handleChange}
          dataTestId="MyActions-Absence-NoContactMade"
        />
      </Grid>
      {(props.absenceData.contactedByTypeId === 1 || props.absenceData.contactedByTypeId === 2) && (
        <Grid item xs={12} md={12}>
          <DateTimePicker
            id="absence-contacted-date"
            label="Contacted date"
            value={props.absenceData.contactDateTime}
            onChange={newValue => {
              props.handleDateChange(newValue, 'contactDateTime')
            }}
            dataTestId="MyActions-Absence-ContactedDate"
          />
        </Grid>
      )}
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          label="Contact Comments"
          rows={5}
          multiline
          value={props.absenceData.contactComments}
          onChange={props.handleChange}
          name="contactComments"
          data-testid="MyActions-Absence-ContactComments"
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          label="Comments"
          rows={5}
          multiline
          value={props.absenceData.comments}
          onChange={props.handleChange}
          name="comments"
          data-testid="MyActions-Absence-Comments"
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <SwitchContainer>
          <CheckBox
            defaultChecked={props.absenceData.sendFdn}
            label="Send FDN"
            name="sendFdn"
            onChange={props.handleCheckboxChange}
            dataTestId="MyActions-Absence-SendFdn"
          />
        </SwitchContainer>
      </Grid>
    </>
  )
}

export default AbsenceForm
