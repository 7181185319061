import { useState, useEffect, useMemo, useCallback } from 'react'
import { GridColDef, GridEventListener, GridFooter, GridFooterContainer } from '@mui/x-data-grid'
import { Grid, Stack, useMediaQuery } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Card from '../../../shared/layout/Card'
import Avatar from '../../../shared/UI/Avatar'
import Paragraph from '../../../shared/UI/Paragraph'
import { StyledLink } from '../../../shared/UI/Link/components'
import { setActiveRoute } from '../../../redux/reducers/appSettingsReducer'
import { DashboardMyActionsResponse } from '../../../types/dashboard-my-actions-response'
import { dashboardService } from '../../../services/dashboardService'
import theme from '../../../theme/theme'
import { isMobileDown } from '../../../theme/deviceChecks'
import FullComponentLoadingIcon from '../../../shared/UI/LoadingIndicator/FullComponentLoadingIcon'
import { DashboardDataGrid } from '../../../shared/UI/DashboardDataGrid'
import NoResult from '../../../shared/UI/NoResult'
import { ProcessRowResponse } from '../../../types/process-row-response'
import { MyActionsHistoryState } from '../../../types/my-actions-history-state'
import { ParagraphWithEllipsis } from '../../../shared/UI/Paragraph/variants/ParagraphWithEllipsis'
import { LinkWithEllipsis } from '../../../shared/UI/Link/variants/LinkWithEllipsis'
import { RootStore } from '../../../redux/store'

interface Rows {
  date: string
  employeeName: string | null
  id: number
  requestStatus: string | null
}

function NoRowsOverlay() {
  return <NoResult message="You have no actions" />
}

export function CustomFooterStatusComponent() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  return (
    <GridFooterContainer>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: '100%' }}
      >
        <>
          <Link
            to="/myactions"
            onClick={() => {
              dispatch(setActiveRoute('/myactions'))
              navigate('/myactions', { state: { previousLocation: '/dashboard' } })
            }}
            style={{ textDecoration: 'none', color: theme.palette.primary.main }}
          >
            <StyledLink>View All</StyledLink>
          </Link>
        </>
        <GridFooter />
      </Stack>
    </GridFooterContainer>
  )
}

function DashboardMyActions() {
  const [myActions, setMyActions] = useState<DashboardMyActionsResponse>()
  const [rows, setRows] = useState<Rows[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const mobile = useMediaQuery(isMobileDown())
  const [lates, setLates] = useState<number[]>([])
  const { userSettings } = useSelector((state: RootStore) => state.appSettings)
  const enhancementsSettingOn = useMemo(() => userSettings?.hasEnhancements, [userSettings])

  const dispatch = useDispatch()

  useEffect(() => {
    setIsLoading(true)
    if (enhancementsSettingOn !== undefined) {
      const getMyActions = enhancementsSettingOn
        ? dashboardService.getMyActionsV2
        : dashboardService.getMyActions

      getMyActions().then(e => {
        setMyActions(e)
        setIsLoading(false)
      })
    }
  }, [enhancementsSettingOn])

  const typeCheck = (cellValueRow: Rows) => {
    switch (cellValueRow.requestStatus) {
      case 'Birthday':
        return `/dashboard/birthdayrequest/${cellValueRow.id}`
      case 'Buy':
        return `/dashboard/buyrequest/${cellValueRow.id}`
      case 'Sell':
        return `/dashboard/sellrequest/${cellValueRow.id}`
      case 'Day Off':
        return `/dashboard/dayoffrequest/${cellValueRow.id}`
      case 'Work From Home':
        return `/dashboard/workfromhomerequest/${cellValueRow.id}`
      case 'Lieu':
      case 'Lieu (M)':
        return `/dashboard/lieurequest/${cellValueRow.id}`
      case 'Holiday':
        return `/dashboard/holidayrequest/${cellValueRow.id}`
      case 'Holiday (M)':
        return `/dashboard/manualholidayrequest/${cellValueRow.id}`
      case 'Shift':
        return `/dashboard/shiftrequest/${cellValueRow.id}`
      case 'Course':
        return `/dashboard/courserequest/${cellValueRow.id}`
      case 'Adjustment':
        return `/dashboard/adjustmentrequest/${cellValueRow.id}`
      case 'Twilight Shift Cover':
        return `/dashboard/twilightshiftcoverrequest/${cellValueRow.id}`
      case 'Night Shift Cover':
        return `/dashboard/nightshiftcoverrequest/${cellValueRow.id}`
      case 'Maternity':
        return `/dashboard/maternityrequest/${cellValueRow.id}`
      case 'Paternity':
        return `/dashboard/paternityrequest/${cellValueRow.id}`
      case 'On Call':
        return `/dashboard/oncallrequest/${cellValueRow.id}`
      case 'Call Out':
        return `/dashboard/calloutrequest/${cellValueRow.id}`
      case 'Night':
        return `/dashboard/nightrequest/${cellValueRow.id}`
      case 'Overtime':
        return `/dashboard/overtimerequest/${cellValueRow.id}`
      case 'Weekend':
        return `/dashboard/weekendrequest/${cellValueRow.id}`
      default:
        if (lates.includes(cellValueRow.id)) {
          return `/dashboard/lateoccurrence/${cellValueRow.id}`
        }
        return `/dashboard/absenceoccurrence/${cellValueRow.id}`
    }
  }

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'photo',
        headerName: '',
        width: 62,
        align: 'left',
        renderCell: cellValues => (
          <Grid item>
            <Avatar employee={cellValues.row.employeeName} />
          </Grid>
        ),
      },
      {
        field: 'employeeName',
        headerName: 'Employee Name',
        flex: 1,
        sortable: true,
        align: 'left',
        renderCell: cellValues => (
          <Stack direction="column" rowGap={0.5} sx={{ overflow: 'hidden' }}>
            <ParagraphWithEllipsis weight="bold">
              {cellValues.row.employeeName}
            </ParagraphWithEllipsis>
            <Link to="" style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
              <LinkWithEllipsis>{cellValues.row.requestStatus}</LinkWithEllipsis>
            </Link>
          </Stack>
        ),
      },
      {
        field: 'date',
        headerName: 'Date',
        type: 'date',
        width: 100,
        align: 'right',
        renderCell: cellValues => (
          <Grid item>
            <Paragraph>{new Date(cellValues.row.date).toLocaleDateString()}</Paragraph>
          </Grid>
        ),
      },
    ],
    []
  )

  const resolveRowStatus = useCallback((status: string): string => {
    const components = status.split('/')
    if (components.length !== 2) {
      return status
    }
    return components[1]
  }, [])

  const processRow = useCallback(
    (status: string | null): ProcessRowResponse => {
      if (!status || !status.toLowerCase().includes('late')) {
        return { status, isLate: false }
      }
      return { status: resolveRowStatus(status), isLate: true }
    },
    [resolveRowStatus]
  )

  useEffect(() => {
    if (!myActions) {
      return
    }

    const myActionsBuffer: Rows[] = []
    const latesIds: number[] = []

    myActions.requests.forEach(request => {
      if (mobile && request.requestStatus !== 'Holiday') {
        return
      }
      const { status, isLate } = processRow(request.requestStatus)
      if (isLate) {
        latesIds.push(request.id)
      }
      myActionsBuffer.push({
        date: request.date,
        employeeName: request.employeeName,
        id: request.id,
        requestStatus: status,
      })
    })

    setRows(myActionsBuffer)
    setLates(latesIds)
  }, [mobile, myActions])

  const navigate = useNavigate()
  const handleRowClick: GridEventListener<'rowClick'> = params => {
    navigate(typeCheck(params.row), {
      state: {
        previousLocation: '/dashboard',
        fromMyActionsDashboard: true,
      } as MyActionsHistoryState,
    })
    dispatch(setActiveRoute('/dashboard'))
  }

  return (
    <Card
      title="My Actions"
      style={{ height: '100%', boxSizing: 'border-box' }}
      testId="dashboard-my-actions"
    >
      <FullComponentLoadingIcon loading={isLoading} removeBorderRadius loadingHeight="253px">
        <DashboardDataGrid
          getRowId={row => row.id}
          rows={rows}
          columns={columns}
          disableSelectionOnClick
          headerHeight={0}
          autoHeight
          pageSize={3}
          density="comfortable"
          onRowClick={handleRowClick}
          components={{
            Footer: CustomFooterStatusComponent,
            NoRowsOverlay,
          }}
          sx={{
            cursor: 'pointer',
            '&.MuiDataGrid-autoHeight': {
              height: '100%',
            },
            '& .MuiDataGrid-virtualScrollerContent': {
              minHeight: '200px!important',
            },
          }}
        />
      </FullComponentLoadingIcon>
    </Card>
  )
}

export default DashboardMyActions
