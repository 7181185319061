import { Grid, TextField } from '@mui/material'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { hideDrawer } from '../../../../redux/reducers/appSettingsReducer'
import { showErrorMessage, showSuccessMessage } from '../../../../redux/reducers/snackbarReducer'
import { RootStore, useAppDispatch } from '../../../../redux/store'
import { manualRequestsService } from '../../../../services/myActionsService'
import Button from '../../../../shared/UI/Button'
import DatePicker from '../../../../shared/UI/DatePicker'
import DropdownMenu from '../../../../shared/UI/DropdownMenu'
import Paragraph from '../../../../shared/UI/Paragraph'
import Tab from '../../../../shared/UI/Tab'
import {
  AbsenceExpectedReturnUpdateRequest,
  DiscountAbsenceRequest,
} from '../../../../types/absence'
import { BaseResponse } from '../../../../types/base-response'
import { getSelectValuesByType } from '../../../../utils/app-utils'
import UserErrorMessage from '../../../../utils/errorFilter'

type Props = {
  absenceData: any
  onSubmit: () => void
  onCancel: () => void
  onUpdate: (field: string, value: any) => void
  getAbsenceData: () => void
}

function OccurrenceActions({ absenceData, onCancel, onUpdate, getAbsenceData }: Props) {
  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const [validationErrors, setValidationErrors] = useState<any>({})
  const [expectedReturnButtonDisabled, setIsExpectedReturnButtonDisabled] = useState(true)
  const [deleteAbsenceButtonDisabled, setIsDeleteAbsenceButtonDisabled] = useState(true)
  const [deleteReason, setDeleteReason] = useState('')
  const [deleteDescription, setDeleteComment] = useState('')
  const [expectedReturn, setexpectedReturn] = useState(absenceData.expectedReturn)
  const [discountAbsenceFrom, setdiscountAbsenceFrom] = useState(absenceData.startDate)
  const [discountAbsenceTo, setdiscountAbsenceTo] = useState(absenceData.endDate)
  const [expectedReturnChanged, setExpectedReturnChanged] = useState(false)
  const [deleteReasonChanged, setDeleteReasonChanged] = useState(false)
  const [deleteDescriptionChanged, setDeleteDescriptionChanged] = useState(false)

  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )

  const activeRoute = useSelector<RootStore, string>(
    (state: RootStore) => state.appSettings.activeRoute
  )

  useEffect(() => {
    if (expectedReturnChanged) {
      setIsExpectedReturnButtonDisabled(false)
    }
  }, [expectedReturnChanged])

  useEffect(() => {
    const hasChanged = deleteReasonChanged && deleteDescriptionChanged
    const hasValue = deleteDescription !== '' && deleteDescription !== null

    if (hasChanged && hasValue) {
      setIsDeleteAbsenceButtonDisabled(false)
    }
  }, [deleteReasonChanged, deleteDescriptionChanged])

  useEffect(() => {
    setdiscountAbsenceFrom(new Date(format(new Date(discountAbsenceFrom), 'yyyy-MM-dd')))
    setdiscountAbsenceTo(new Date(format(new Date(discountAbsenceTo), 'yyyy-MM-dd')))
  }, [])

  function submitExpectedReturn() {
    if (!expectedReturnChanged) {
      return
    }

    const absenceObj: AbsenceExpectedReturnUpdateRequest = {
      absenceId: absenceData.id,
      expectedReturn,
    }

    manualRequestsService
      .updateExpectedReturn(absenceObj)
      .then(res => {
        onUpdate('expectedReturn', expectedReturn)
        dispatch(showSuccessMessage('Success!'))
        dispatch(hideDrawer())
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }

  function submitDiscountAbsence() {
    const absenceObj: DiscountAbsenceRequest = {
      absenceId: absenceData.id,
      dateFrom: format(discountAbsenceFrom, 'yyyy-MM-dd'),
      dateTo: format(discountAbsenceTo, 'yyyy-MM-dd'),
    }

    manualRequestsService
      .createDiscountAbsenceDay(absenceObj)
      .then(res => {
        getAbsenceData()
        dispatch(showSuccessMessage('Successfully discounted absence days'))
        dispatch(hideDrawer())
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }

  function deleteAbsence() {
    setValidationErrors({})
    const errors: any = {}

    if (deleteDescription.length === 0) {
      errors.deleteDescription = true
    }

    if (deleteReason.length === 0) {
      errors.deleteReason = true
    }

    if (Object.keys(errors).length !== 0) {
      setValidationErrors(errors)
      return
    }

    const absenceObj = {
      absenceId: absenceData.id,
      deleteReason: String(deleteReason),
      deleteDescription,
    }

    manualRequestsService
      .deleteAbsenceOccurrence(absenceObj)
      .then(res => {
        dispatch(showSuccessMessage('Absence occurrence deleted successfully!'))
        dispatch(hideDrawer())
        navigate(activeRoute || '/myactions')
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }

  return (
    <>
      <Tab
        titleAndContent={[
          {
            label: 'Expected Return',
            tabContext: (
              <>
                <Grid container xl={12} spacing={4}>
                  <Grid item xs={12} mt={4}>
                    <Paragraph weight="bold">Details</Paragraph>
                  </Grid>
                  <Grid item xs={12}>
                    <DatePicker
                      label="Set expected return date"
                      value={expectedReturn}
                      onChange={e => {
                        setexpectedReturn(e)
                        setExpectedReturnChanged(true)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} gap={4} display="flex" justifyContent="flex-end">
                    <Button
                      label="Cancel"
                      color="secondary"
                      variant="outlined"
                      onClick={onCancel}
                    />
                    <Button
                      label="Submit"
                      onClick={() => submitExpectedReturn()}
                      disabled={!expectedReturnChanged}
                    />
                  </Grid>
                </Grid>
              </>
            ),
          },
          {
            label: 'Discount Absence',
            tabContext: (
              <>
                <Grid container xl={12} spacing={4}>
                  <Grid item xs={12} mt={4}>
                    <Paragraph weight="bold">Details</Paragraph>
                  </Grid>
                  <Grid item xs={12}>
                    <DatePicker
                      label="Date From"
                      value={discountAbsenceFrom}
                      onChange={e => {
                        setdiscountAbsenceFrom(e)
                      }}
                      disabled={!userPermissions.includes('DiscountAbsence')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DatePicker
                      label="Date To"
                      value={discountAbsenceTo}
                      onChange={e => {
                        setdiscountAbsenceTo(e)
                      }}
                      disabled={!userPermissions.includes('DiscountAbsence')}
                    />
                  </Grid>
                  <Grid item xs={12} gap={4} display="flex" justifyContent="flex-end">
                    <Button
                      label="Cancel"
                      color="secondary"
                      variant="outlined"
                      onClick={onCancel}
                    />
                    <Button label="Submit" onClick={() => submitDiscountAbsence()} />
                  </Grid>
                </Grid>
              </>
            ),
            disabled: !userPermissions.includes('DiscountAbsence'),
          },
          {
            label: 'Delete Absence',
            tabContext: (
              <>
                <Grid container xl={12} spacing={4}>
                  <Grid item xs={12} mt={4}>
                    <Paragraph weight="bold">Details</Paragraph>
                  </Grid>
                  <Grid item xs={12}>
                    <DropdownMenu
                      id="reason"
                      label="Reason"
                      textField="displayValue"
                      valueField="value"
                      data={getSelectValuesByType('AbsenceDeletionReason')}
                      onChange={e => {
                        setDeleteReason(e.target.value)
                        setDeleteReasonChanged(true)
                      }}
                      disabled={!userPermissions.includes('DeleteAttendance')}
                      helperText="Required"
                      error={validationErrors.deleteReason}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="description"
                      label="Description"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={5}
                      value={deleteDescription}
                      onChange={e => {
                        setDeleteComment(e.target.value)
                        setDeleteDescriptionChanged(true)
                      }}
                      disabled={!userPermissions.includes('DeleteAttendance')}
                      helperText="Required"
                      error={validationErrors.deleteDescription}
                    />
                  </Grid>
                  <Grid item xs={12} gap={4} display="flex" justifyContent="flex-end">
                    <Button
                      label="Cancel"
                      color="secondary"
                      variant="outlined"
                      onClick={onCancel}
                    />
                    <Button label="Delete Absence" onClick={() => deleteAbsence()} />
                  </Grid>
                </Grid>
              </>
            ),
            disabled: !userPermissions.includes('DeleteAttendance'),
          },
        ]}
      />
    </>
  )
}

export default OccurrenceActions
