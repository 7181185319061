import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect, useCallback } from 'react'
import { Grid } from '@mui/material'
import Paragraph from '../../../shared/UI/Paragraph'
import { boughtSoldType, MyRequestsSummaryType } from '../../../types/my-requests-summary-type'
import NoDataFound from '../../../shared/UI/NoDataFound'
import LoadingIndicator from '../../../shared/UI/LoadingIndicator'
import { EntitlementSplit } from '../../../types/entitlement-split'
import { RootStore } from '../../../redux/store'
import { availabilityService } from '../../../services/availabilityService'
import { showErrorMessage } from '../../../redux/reducers/snackbarReducer'
import MySummaryEntitlementSplit from '../../../shared/UI/MySummaryEntitlementSplit/MySummaryEntitlementSplit'
import UserErrorMessage from '../../../utils/errorFilter'
import { BaseResponse } from '../../../types/base-response'
import Button from '../../../shared/UI/Button'
import SummaryDetailRow from './SummaryDetailRow'

interface Props {
  onCancel?: (e: any) => void
}

export function MyRequestsSummaryItems({ onCancel }: Props) {
  const [baseAllowance, setBaseAllowance] = useState<number>()
  const [workingFromHomeAllowance, setWorkingFromHomeAllowance] = useState<number>()
  const [workingFromHomeHours, setWorkingFromHomeHours] = useState<number>()
  const [fireMarshalAllowance, setFireMarshalAllowance] = useState<number>()
  const [firstAiderAllowance, setFirstAiderAllowance] = useState<number>()
  const [boughtHours, setBoughtHours] = useState<boughtSoldType>()
  const [soldHours, setSoldHours] = useState<boughtSoldType>()
  const [serviceHours, setServiceHours] = useState<number>()
  const [birthday, setBirthday] = useState<string>()
  const [entitlementSplit, setEntitlementSplit] = useState<EntitlementSplit>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [summaryItems, setSummaryItems] = useState<MyRequestsSummaryType | null>()

  const { selectedYear } = useSelector((state: RootStore) => state.myRequests)
  const { userSettings } = useSelector((state: RootStore) => state.appSettings)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!selectedYear) {
      return
    }

    setIsLoading(true)
    availabilityService
      .getSummary(selectedYear.value)
      .then(summary => {
        setBaseAllowance(summary.baseAllowance)
        setBoughtHours({
          hoursBoughtSold: summary.hoursBoughtToDate,
          remaining: summary.maximumHoursAvailableToBuy,
        })
        setSoldHours({
          hoursBoughtSold: summary.hoursSoldToDate,
          remaining: summary.maximumHoursAvailableToSell,
        })
        setServiceHours(summary.serviceHours)
        setWorkingFromHomeAllowance(summary.workingFromHomeAllowance)
        setWorkingFromHomeHours(summary.workingFromHomeHours)
        setFireMarshalAllowance(summary.fireMarshalAllowance)
        setFirstAiderAllowance(summary.firstAiderAllowance)
        setBirthday(summary.hasBookedBirthday ? 'Yes' : 'No')
        setIsLoading(false)
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })

    setIsLoading(true)
    availabilityService
      .getAvailability(selectedYear.value)
      .then(availability => {
        setEntitlementSplit({
          general: {
            hours: availability?.entitlement.totalHours,
            summerHours: availability?.entitlement.totalSummerHours,
            winterHours: availability?.entitlement.totalWinterHours,
          },
          remaining: {
            hours: availability?.entitlement.remainingHours,
            summerHours: availability?.entitlement.remainingSummerHours,
            winterHours: availability?.entitlement.remainingWinterHours,
          },
          requested: {
            hours: availability?.entitlement.requestedHours,
            summerHours: availability?.entitlement.requestedSummerHours,
            winterHours: availability?.entitlement.requestedWinterHours,
          },
          changeRequests: {
            hours: availability?.entitlement.changeRequestHours,
            summerHours: availability?.entitlement.changeRequestHoursSummer,
            winterHours: availability?.entitlement.changeRequestHoursWinter,
          },
          ifApproved: {
            hours: availability?.entitlement.ifApprovedHours,
            summerHours: availability?.entitlement.ifApprovedHoursSummer,
            winterHours: availability?.entitlement.ifApprovedHoursWinter,
          },
        })
        setIsLoading(false)
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }, [selectedYear])

  useEffect(() => {
    setSummaryItems({
      entitlementSplit,
      baseAllowance,
      additionalServiceHours: serviceHours,
      workingFromHomeAllowance,
      workingFromHomeHours,
      fireMarshalAllowance,
      firstAiderAllowance,
      hoursBought: boughtHours,
      hoursSold: soldHours,
      birthdayBooked: birthday,
    } as MyRequestsSummaryType)
  }, [
    baseAllowance,
    birthday,
    boughtHours,
    entitlementSplit,
    fireMarshalAllowance,
    firstAiderAllowance,
    serviceHours,
    soldHours,
    workingFromHomeAllowance,
    workingFromHomeHours,
  ])

  const formatAllowanceForSummaryRow = useCallback((allowance: number, value?: number): string => {
    if (value !== undefined) {
      return `${(value.toFixed(2) && value.toFixed(2)) || 0.0} / ${
        (allowance.toFixed(2) && allowance.toFixed(2)) || 0.0
      }`
    }
    return `${allowance.toFixed(2) && allowance.toFixed(2)}`
  }, [])

  return (
    <>
      {summaryItems && entitlementSplit ? (
        <Grid container gap={4}>
          <MySummaryEntitlementSplit summaryItems={entitlementSplit} />
          <Grid
            sx={{ backgroundColor: '#F7F7F7', borderRadius: '4px', flex: 1, flexGrow: 1 }}
            m="0 -15px"
          >
            <Paragraph weight="bold" padding="10px 15px">
              Summary Detail
            </Paragraph>
          </Grid>
          <Grid container spacing={1} display="flex">
            <SummaryDetailRow
              title="Base Allowance"
              rowValue={formatAllowanceForSummaryRow(summaryItems.baseAllowance)}
            />
            {summaryItems.additionalServiceHours !== 0 && (
              <SummaryDetailRow
                title="Additional Service"
                rowValue={formatAllowanceForSummaryRow(summaryItems.additionalServiceHours)}
              />
            )}
            {summaryItems.workingFromHomeAllowance > 0 && userSettings?.isWfhRequestAllowed && (
              <SummaryDetailRow
                title="Work From Home Allowance"
                rowValue={formatAllowanceForSummaryRow(
                  summaryItems.workingFromHomeAllowance,
                  summaryItems.workingFromHomeHours
                )}
              />
            )}
            {summaryItems.fireMarshalAllowance > 0 && (
              <SummaryDetailRow
                title="Fire Marshal Allowance"
                rowValue={formatAllowanceForSummaryRow(summaryItems.fireMarshalAllowance)}
              />
            )}
            {summaryItems.firstAiderAllowance > 0 && (
              <SummaryDetailRow
                title="First Aider Allowance"
                rowValue={formatAllowanceForSummaryRow(summaryItems.firstAiderAllowance)}
              />
            )}
            {userSettings?.isBuyRequestAllowed && (
              <SummaryDetailRow
                title="Hours Bought"
                rowValue={formatAllowanceForSummaryRow(
                  summaryItems.hoursBought?.remaining,
                  summaryItems.hoursBought?.hoursBoughtSold
                )}
              />
            )}
            {userSettings?.isSellRequestAllowed && (
              <SummaryDetailRow
                title="Hours Sold"
                rowValue={formatAllowanceForSummaryRow(
                  summaryItems.hoursSold?.remaining,
                  summaryItems.hoursSold?.hoursBoughtSold
                )}
              />
            )}
            {userSettings?.isBirthdayRequestAllowed && (
              <SummaryDetailRow
                title="Birthday Booked"
                rowValue={summaryItems.birthdayBooked && summaryItems.birthdayBooked}
              />
            )}
          </Grid>
          <Button label="Close" color="secondary" variant="outlined" onClick={onCancel} />
        </Grid>
      ) : (
        <>
          <NoDataFound show={!isLoading && !summaryItems?.entitlementSplit && !summaryItems} />
          <LoadingIndicator show={isLoading} />
        </>
      )}
    </>
  )
}
