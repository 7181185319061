import { Box, Grid, TextField, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { DateRange } from '@mui/x-date-pickers-pro'
import { ReactElement } from 'react'
import { SwitchContainer } from '../components'
import { showErrorMessage } from '../../../redux/reducers/snackbarReducer'
import CheckBox from '../../../shared/UI/CheckBox'
import DateTimePicker from '../../../shared/UI/DateTimePicker'
import { validateAbsentFrom } from '../utils'
import InfoPopper from '../../../shared/UI/InfoPopper'
import { IOSStyleSwitch } from '../../../shared/UI/IOSStyleSwitch/IOSStyleSwitch'

interface PartDayAbsenceFormProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  popperText: ReactElement | string
  shiftDescription: string
  dateRange: DateRange<Date>
  absentFrom: Date
  hasHadLunch: boolean
  toWorkBack: boolean
  handleDateChange: (selectedDate: Date | null, name: string, keyboardInputValue?: string) => void
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  setAbsentFromDate: React.Dispatch<React.SetStateAction<Date | undefined>>
}

function PartDayAbsenceForm({
  isOpen,
  setIsOpen,
  popperText,
  shiftDescription,
  dateRange,
  absentFrom,
  hasHadLunch,
  toWorkBack,
  handleDateChange,
  handleCheckboxChange,
  setAbsentFromDate,
}: PartDayAbsenceFormProps): JSX.Element {
  const dispatch = useDispatch()

  const handleValidateAbsentFrom = (value: Date | null, onSuccess: () => void) => {
    validateAbsentFrom(
      value,
      shiftDescription,
      dateRange,
      () => {
        onSuccess()
      },
      error => dispatch(showErrorMessage(error))
    )
  }

  return (
    <>
      <Grid item xs={12} md={12}>
        <SwitchContainer>
          <Box display="flex" alignItems="center">
            <Typography align="center" variant="body1">
              Part Day Absence
            </Typography>
            <InfoPopper popperText={popperText} style={{ marginLeft: '10px' }} />
          </Box>

          <IOSStyleSwitch
            checked={isOpen}
            name="partDayAbsence"
            onChange={handleCheckboxChange}
            onClick={() => setIsOpen(!isOpen)}
            data-testid="MyActions-Absence-PartDayAbsence"
          />
        </SwitchContainer>
      </Grid>
      {isOpen ? (
        <>
          <Grid item xs={12} md={12}>
            <DateTimePicker
              id="absence-from"
              label="Absent from"
              value={absentFrom}
              onChange={newValue =>
                handleValidateAbsentFrom(newValue, () => handleDateChange(newValue, 'absentFrom'))
              }
              customOnClose={(fullDate: Date) => {
                handleValidateAbsentFrom(fullDate, () => setAbsentFromDate(fullDate))
              }}
              dataTestId="MyActions-Absence-PartDayAbsence-DateTime"
            />
          </Grid>
          <Grid item xs={12} md={12} mt={-1}>
            <SwitchContainer>
              <CheckBox
                defaultChecked={hasHadLunch}
                label="Has had lunch"
                name="hasHadLunch"
                onChange={handleCheckboxChange}
                dataTestId="MyActions-Absence-PartDayAbsence-hasHadLunch"
              />
            </SwitchContainer>
            <SwitchContainer>
              <CheckBox
                defaultChecked={toWorkBack}
                label="To work back"
                name="toWorkBack"
                onChange={handleCheckboxChange}
                dataTestId="MyActions-Absence-PartDayAbsence-ToWorkBack"
              />
            </SwitchContainer>
          </Grid>
        </>
      ) : (
        <Grid item xs={12} md={12}>
          <SwitchContainer>
            <CheckBox
              defaultChecked={toWorkBack}
              label="To work back"
              name="toWorkBack"
              onChange={handleCheckboxChange}
            />
          </SwitchContainer>
        </Grid>
      )}
    </>
  )
}

export default PartDayAbsenceForm
