import { useState, useMemo, useEffect } from 'react'
import { DateRange } from '@mui/x-date-pickers-pro'
import {
  DefaultHoursResponse,
  DefaultHoursParams,
} from '../../models/enhancement/default-department-hours'
import { v2MyActionsService } from '../../services/myActionsServiceV2'
import { RequestType } from '../../models'
import { formatLocalDateYmd } from '../date-utils'

interface UseDefaultHoursProps {
  departmentId: number
  teamId: number | undefined
  dateRange: DateRange<Date>
  type: RequestType
}

export const useDefaultHours = ({ departmentId, teamId, dateRange, type }: UseDefaultHoursProps) => {
  const [defaultHours, setDefaultHours] = useState<DefaultHoursResponse["defaultHours"] | null>(null)

  const dates = useMemo(() => {
    if (!dateRange[0] || !dateRange[1]) return []

    const days = []
    const currentDate = new Date(dateRange[0])
    while (currentDate <= dateRange[1]) {
      days.push(formatLocalDateYmd(currentDate))
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return days
  }, [dateRange])

  const getDefaultDepartmentHours = v2MyActionsService.getDefaultDepartmentOnCallHours

  useEffect(() => {
    const fetchDefaultHours = async () => {
      try {
        const bodyObject: DefaultHoursParams = { departmentId, teamId, dates }
        const response = await getDefaultDepartmentHours(bodyObject)
        setDefaultHours(response.defaultHours)
      } catch (error) {
        console.error('Error fetching department hours:', error)
      }
    }

    const isOnCallType = type === RequestType.ON_CALL || type === RequestType.ON_CALL_M

    if (dates.length > 0 && isOnCallType) {
      fetchDefaultHours()
    }
  }, [dates, departmentId, teamId, type, getDefaultDepartmentHours])

  const getDefaultEnhancementDayHours = useMemo(() => (day: Date): number => {
    const isOnCallType = type === RequestType.ON_CALL || type === RequestType.ON_CALL_M

    if (!isOnCallType) {
      return 0
    }

    const dateKey = formatLocalDateYmd(day)
    const hoursFromAPI = defaultHours ? defaultHours[dateKey] : undefined
    return hoursFromAPI !== undefined ? hoursFromAPI : 0
  }, [defaultHours, type])

  return {
    defaultHours,
    getDefaultEnhancementDayHours,
    dates,
  }
}
