import { useCallback, useMemo } from 'react'
import {
  DateRangePicker as MuiDateRangePicker,
  DateRange,
} from '@mui/x-date-pickers-pro/DateRangePicker'
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Box, Grid, TextField, useMediaQuery } from '@mui/material'
import {
  DateRangePickerDay,
  DateRangePickerDayProps,
  LocalizationProvider,
} from '@mui/x-date-pickers-pro'
import locale from 'date-fns/locale/en-GB'
import { format, addMonths, addDays } from 'date-fns'
import theme from '../../../theme/theme'
import { DateFormats } from '../../../api/absence/tempTypes/generalprops'
import { RequestTypeNameString } from '../../../models'

type Props = {
  dataTestId?: string
  name?: string
  id?: string
  onChange: (date: DateRange<Date>, keyboardInputValue?: string | undefined) => void
  onOpen?: () => void
  value: DateRange<Date>
  arrange?: 'Horizontal' | 'Stack'
  fromDateError?: boolean
  toDateError?: boolean
  required?: boolean
  error?: boolean
  helperText?: 'Required' | 'Optional'
  isHTL?: boolean
  isHss?: boolean
  maxDate?: Date | undefined
  /** Should be ISO format e.g. 2022-01-01 */
  disabledDates?: string[]
  startText?: string
  endText?: string
  disabled?: boolean
  disablePast?: boolean
  disableOnOrAfterMonths?: number
  closeOnSelect?: boolean
  calendars?: 1 | 2 | 3
  spacing?: 1 | 2 | 3
  weekendOnly?: boolean
}

function DateRangePicker({
  dataTestId,
  onChange,
  onOpen = undefined,
  value,
  arrange = 'Stack',
  fromDateError,
  toDateError,
  required,
  error,
  helperText,
  isHTL,
  isHss,
  maxDate,
  disabledDates,
  startText = 'From',
  endText = 'To',
  disabled = false,
  disablePast = false,
  disableOnOrAfterMonths = undefined,
  closeOnSelect = true,
  calendars = 2,
  spacing = 3,
  weekendOnly = false,
  ...rest
}: Props) {
  const columns = arrange === 'Stack' ? 12 : 6
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault()
  }, [])

  const fromDate = useMemo(() => {
    const [first] = value
    return first
  }, [value])

  const toDate = useMemo(() => {
    const [_, second] = value
    return second
  }, [value])

  const disableAfterDate = useMemo(
    () =>
      fromDate && disableOnOrAfterMonths ? addMonths(fromDate, disableOnOrAfterMonths) : undefined,
    [disableOnOrAfterMonths, fromDate]
  )

  const disableBeforeDate = useMemo(
    () =>
      toDate && disableOnOrAfterMonths ? addMonths(toDate, disableOnOrAfterMonths * -1) : undefined,
    [disableOnOrAfterMonths, toDate]
  )

  const disableDate = useCallback(
    (date: Date): boolean => {
      if (disableAfterDate && fromDate && !toDate) {
        if (date >= disableAfterDate) {
          return true
        }
      }

      if (disableBeforeDate && toDate && !fromDate) {
        if (date <= addDays(disableBeforeDate, 1)) {
          return true
        }
      }

      if (disableBeforeDate && disableAfterDate) {
        if (date >= disableAfterDate) {
          return true
        }
      }

      if (weekendOnly) {
        const day = date.getDay()
        return day !== 0 && day !== 6
      }

      return disabledDates
        ? disabledDates.some(
            disabledDate =>
              format(date, DateFormats.Date) === format(new Date(disabledDate), DateFormats.Date)
          )
        : false
    },
    [disableBeforeDate, disableAfterDate, disabledDates, fromDate, toDate, weekendOnly]
  )

  const renderDisabledDay = useCallback(
    (date: Date, dateRangePickerDayProps: DateRangePickerDayProps<Date>) => {
      const defaultSx = {
        '&.MuiPickersDay-today.MuiButtonBase-root:not(.Mui-selected)': {
          border: '1px solid rgba(32,197,160,0.3)',
          backgroundColor: 'rgba(32,197,160,0.3)',
        },
      }
      const asDisabled = {
        '&.Mui-disabled': {
          color: 'rgba(0,0,0,0.2)',
        },
      }

      return (
        <DateRangePickerDay
          {...dateRangePickerDayProps}
          day={date}
          sx={disableDate(date) ? { ...asDisabled, ...defaultSx } : defaultSx}
        />
      )
    },
    [disableDate]
  )

  const DateFromText = 'Tab-DateFrom'
  const DateToText = 'Tab-DateTo'

  return (
    <Box width={arrange === 'Stack' ? 'unset' : '100%'}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        localeText={{ start: startText, end: endText }}
        locale={locale}
      >
        {mobile && (
          <MobileDateRangePicker
            shouldDisableDate={date => disableDate(date)}
            inputFormat="dd/MM/yyyy"
            value={value}
            disabled={disabled}
            calendars={calendars}
            onChange={onChange}
            maxDate={maxDate}
            renderDay={renderDisabledDay}
            closeOnSelect={closeOnSelect}
            onOpen={onOpen}
            renderInput={(startProps, endProps) => (
              <Grid container spacing={spacing}>
                <Grid item xs={columns}>
                  <TextField
                    {...startProps}
                    fullWidth
                    error={fromDateError}
                    autoComplete="off"
                    helperText={helperText}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>
                <Grid item xs={columns}>
                  <TextField
                    {...endProps}
                    fullWidth
                    error={toDateError}
                    autoComplete="off"
                    helperText={helperText}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>
              </Grid>
            )}
          />
        )}
        {!mobile && (
          <MuiDateRangePicker
            shouldDisableDate={date => disableDate(date)}
            inputFormat="dd/MM/yyyy"
            value={value}
            disabled={disabled}
            calendars={calendars}
            onChange={onChange}
            maxDate={maxDate}
            disablePast={disablePast}
            renderDay={renderDisabledDay}
            closeOnSelect={closeOnSelect}
            onOpen={onOpen}
            renderInput={(startProps, endProps) => (
              <Grid container spacing={spacing}>
                <Grid item xs={columns}>
                  <TextField
                    {...startProps}
                    fullWidth
                    error={fromDateError}
                    autoComplete="off"
                    helperText={helperText}
                    onKeyDown={handleKeyDown}
                    data-testid={`${dataTestId}${DateFromText}`}
                  />
                </Grid>
                <Grid item xs={columns}>
                  <TextField
                    {...endProps}
                    fullWidth
                    error={toDateError}
                    autoComplete="off"
                    helperText={helperText}
                    onKeyDown={handleKeyDown}
                    data-testid={`${dataTestId}${DateToText}`}
                  />
                </Grid>
              </Grid>
            )}
          />
        )}
      </LocalizationProvider>
    </Box>
  )
}

export default DateRangePicker
