import { RequestType } from '../../models'
import { enhancementTypes } from '../constants'

export const enhancementsList = [
  enhancementTypes.overtime,
  enhancementTypes.night,
  enhancementTypes.onCall,
  enhancementTypes.callOut,
  enhancementTypes.weekend,
]
export const enhancementsRequestTypes = [
  RequestType.OVERTIME,
  RequestType.NIGHT,
  RequestType.OVERTIME_M,
  RequestType.NIGHT_M,
  RequestType.ON_CALL,
  RequestType.ON_CALL_M,
  RequestType.CALL_OUT,
  RequestType.CALL_OUT_M,
  RequestType.WEEKEND,
]

export const isEnhancement = (requestType: string): boolean =>
  enhancementsList.includes(requestType)

export const isEnhancementByRequestType = (requestType: RequestType) =>
  enhancementsRequestTypes.includes(requestType)
