import CancelIcon from '@mui/icons-material/Cancel'
import { Button, CircularProgress, Grid, Typography, useMediaQuery } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { displayAbsenceDrawer, setActiveRoute } from '../../../redux/reducers/appSettingsReducer'
import { setDeskBookingShowMeBookingID } from '../../../redux/reducers/deskBookingReducer'
import { showErrorMessage } from '../../../redux/reducers/snackbarReducer'
import { RootStore } from '../../../redux/store'
import { notificationService } from '../../../services/notificationService'
import { isMobileDown } from '../../../theme/deviceChecks'
import { BaseResponse } from '../../../types/base-response'
import { EmployeeDetailsResponse } from '../../../types/employee'
import UserErrorMessage from '../../../utils/errorFilter'
import Paragraph from '../Paragraph'
import {
  NotificationContentProps,
  NotificationItem,
  NotificationItemResponse,
  NotificationResponse,
} from './type'

const notificationTypeManagerActionList: string[] = [
  'absence',
  'request',
  'deskbookingmanagercreation',
  'deskbookingforemployeebymanagercreation',
  'overtime',
  'call out',
  'night',
  'on call',
  'weekend',
]
const notificationTypeEmployeeActionList: string[] = [
  'absence',
  'request',
  'deskbookingemployeecreation',
  'deskbookingformanagerforemployeecreation',
  'overtime',
  'call out',
  'night',
  'on call',
  'weekend',
]
// general reminders that are not tied to one specific action made by a user,
// but are instead a prompt for an action that can involve multiple requests
const notificationTypeGeneralReminderList: string[] = [
  'enhancementsreviewreminder',
  'enhancementspayrollsubmissionreminder',
]

const isManagerOfTargetEmployee = (currentEmployeeId: number, targetEmployeeId: number) =>
  targetEmployeeId !== undefined && currentEmployeeId !== targetEmployeeId

const isReminderNotification = (notificationType: string) =>
  notificationTypeGeneralReminderList.includes(notificationType)

function NotificationContent({ onLoad, onClose }: NotificationContentProps) {
  const [notificationList, setNotificationList] = useState<NotificationItem[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isMobileScreenWidth = useMediaQuery(isMobileDown())
  const isDeviceMobile = useMemo(() => isMobileScreenWidth || isMobile, [isMobileScreenWidth])

  const employeeDetails = useSelector<RootStore, EmployeeDetailsResponse>(
    (state: RootStore) => state.appSettings.employeeDetails
  )

  const handleClearNotification = (
    notificationId: number,
    recipientId: number,
    changedById: number
  ) => {
    const list = [...notificationList]
    list.find(item => item.id === notificationId)!.isLoading = true

    setNotificationList(list)

    notificationService
      .clearNotification(notificationId, recipientId, changedById)
      .then(res => {
        const removeList = [...notificationList]
        const removeIndex = removeList.findIndex(item => item.id === notificationId)
        if (removeIndex > -1) {
          removeList.splice(removeIndex, 1)
        }

        setNotificationList(removeList)

        if (removeList.length === 0) {
          onClose()
        }
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }

  const navigateToMyActionsRoute = (path: string) => {
    dispatch(setActiveRoute('/myactions'))
    navigate(path)
  }

  const handleNavigation = (
    id: number,
    targetEmployeeId: number,
    type: string,
    header: string,
    requestType: string | undefined
  ) => {
    const isManager: boolean = isManagerOfTargetEmployee(
      employeeDetails.employeeId,
      targetEmployeeId
    )
    const lowerCaseType = type.toLowerCase()
    const refreshurlepoch: number = new Date().getTime()

    const handleManagerNavigation = () => {
      switch (lowerCaseType) {
        case 'absence':
          if (header.match(/^RTW Required/i)) {
            navigateToMyActionsRoute(
              `/myactions/absenceoccurrence/${id}/${refreshurlepoch}?showDrawer=completeRTW`
            )
          } else {
            navigateToMyActionsRoute(`/myactions/absenceoccurrence/${id}`)
          }
          break
        case 'request': {
          const requestRoutes: { [key: string]: string } = {
            Sell: `/myactions/sellrequest/${id}`,
            Buy: `/myactions/buyrequest/${id}`,
            Birthday: `/myactions/birthdayrequest/${id}`,
            Lieu: `/myactions/lieurequest/${id}`,
            DayOff: `/myactions/dayoffrequest/${id}`,
            Shift: `/myactions/shiftrequest/${id}`,
            WorkFromHome: `/myactions/workfromhomerequest/${id}`,
            Manual: `/myactions/manualholidayrequest/${id}`,
            Holiday: `/myactions/holidayrequest/${id}`,
          }
          if (requestType && requestRoutes[requestType]) {
            navigateToMyActionsRoute(requestRoutes[requestType])
          }
          break
        }
        case 'overtime':
          navigateToMyActionsRoute(`/myactions/overtimerequest/${id}`)
          break
        case 'night':
          navigateToMyActionsRoute(`/myactions/nightrequest/${id}`)
          break
        case 'on call':
          navigateToMyActionsRoute(`/myactions/oncallrequest/${id}`)
          break
        case 'call out':
          navigateToMyActionsRoute(`/myactions/calloutrequest/${id}`)
          break
        case 'weekend':
          navigateToMyActionsRoute(`/myactions/weekendrequest/${id}`)
          break
        case 'deskbookingmanagercreation':
        case 'deskbookingemployeecreation':
        case 'deskbookingforemployeebymanagercreation':
        case 'deskbookingformanagerforemployeecreation':
          navigate('/booking')
          dispatch(setActiveRoute('/booking'))
          dispatch(setDeskBookingShowMeBookingID(id))
          break
        default:
          dispatch(setActiveRoute('/myactions'))
          navigate(`/myactions`)
          break
      }
      onClose()
    }

    const handleSubordinateNavigation = () => {
      switch (lowerCaseType) {
        case 'absence':
          if (header.match(/^SCA Required/i) || header.match(/^ED Required/i)) {
            dispatch(setActiveRoute('/myavailability'))
            navigate(`/myavailability/absenceoccurrence/${id}/${refreshurlepoch}?showDrawer=addSCA`)
          } else {
            dispatch(setActiveRoute('/myavailability'))
            navigate(`/myavailability/absenceoccurrence/${id}`)
          }
          break
        case 'request':
        case 'overtime':
        case 'night':
        case 'on call':
        case 'call out':
        case 'weekend':
          dispatch(setActiveRoute('/myavailability'))
          dispatch(displayAbsenceDrawer())
          navigate(`/myavailability/requestoccurrence/${id}/${refreshurlepoch}`)
          break
        case 'deskbookingmanagercreation':
        case 'deskbookingemployeecreation':
        case 'deskbookingforemployeebymanagercreation':
        case 'deskbookingformanagerforemployeecreation':
          navigate('/booking')
          dispatch(setActiveRoute('/booking'))
          dispatch(setDeskBookingShowMeBookingID(id))
          break
        default:
          dispatch(setActiveRoute('/myavailability'))
          navigate(`/myavailability`)
          break
      }
      onClose()
    }

    const handleReminderNavigation = () => {
      switch (lowerCaseType) {
        case 'enhancementsreviewreminder':
          dispatch(setActiveRoute('/myactions'))
          navigate('/myactions')
          break
        case 'enhancementspayrollsubmissionreminder':
          dispatch(setActiveRoute('/enhancements'))
          navigate('/enhancements')
          break
        default:
          break
      }
    }

    if (isReminderNotification(lowerCaseType)) {
      handleReminderNavigation()
    } else if (isManager && notificationTypeManagerActionList.includes(lowerCaseType)) {
      handleManagerNavigation()
    } else if (!isManager && notificationTypeEmployeeActionList.includes(lowerCaseType)) {
      handleSubordinateNavigation()
    }
  }

  const getNotifications = useCallback(() => {
    notificationService
      .getNotifications()
      .then((res: NotificationResponse) => {
        const list: NotificationItem[] = res.notifications
          .map((item: NotificationItemResponse) => ({
            id: item.id,
            additionalProperties: item.additionalProperties,
            additionalPropertiesParsed: JSON.parse(item.additionalProperties),
            timestamp: item.timestamp,
            recipientId: item.recipientId,
            notificationStateId: item.notificationStateId,
            notificationStateStr: item.notificationStateStr,
            notificationType: item.notificationType,
            isLoading: false,
          }))
          .filter(notification => !(isDeviceMobile && notification.notificationType === 'Absence'))

        setNotificationList(list)
        setIsLoading(false)
        onLoad(res.notifications.length)
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }, [dispatch, isDeviceMobile, onLoad])

  const notificationListHasLength = useMemo(
    () => notificationList.length > 0,
    [notificationList.length]
  )
  const lastNotificationId = useMemo(() => notificationList[0]?.id, [notificationList])

  const getNewNotifications = useCallback(() => {
    if (notificationListHasLength) {
      notificationService
        .getNewNotifications(lastNotificationId)
        .then((res: NotificationResponse) => {
          const newList: NotificationItem[] = res.notifications
            .map((item: NotificationItemResponse) => ({
              id: item.id,
              additionalProperties: item.additionalProperties,
              additionalPropertiesParsed: JSON.parse(item.additionalProperties),
              timestamp: item.timestamp,
              recipientId: item.recipientId,
              notificationStateId: item.notificationStateId,
              notificationStateStr: item.notificationStateStr,
              notificationType: item.notificationType,
              isLoading: false,
            }))
            .filter(
              notification => !(isDeviceMobile && notification.notificationType === 'Absence')
            )
          setNotificationList(current => [...current, ...newList])
        })
    }
  }, [isDeviceMobile, lastNotificationId, notificationListHasLength])

  useEffect(() => {
    if (notificationList.length === 0) {
      getNotifications()
    } else {
      getNewNotifications()
    }
  }, [getNewNotifications, getNotifications, notificationList.length])

  const getCursor = (targetEmployeeId: number, type: string) => {
    const lowerCaseType = type.toLocaleLowerCase()
    if (isReminderNotification(lowerCaseType)) {
      return 'pointer'
    }

    const isManager: boolean = isManagerOfTargetEmployee(
      employeeDetails.employeeId,
      targetEmployeeId
    )
    if (isManager && notificationTypeManagerActionList.includes(lowerCaseType)) {
      return 'pointer'
    }
    if (!isManager && notificationTypeEmployeeActionList.includes(lowerCaseType)) {
      return 'pointer'
    }
    return 'inherit'
  }

  return (
    <>
      {isLoading && (
        <div className="item itemlast loading">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          </Grid>
        </div>
      )}
      {!isLoading &&
        notificationList.map((item, index) => (
          <div
            key={item.id}
            className={index === notificationList.length - 1 ? 'item itemlast' : 'item'}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={8}
                sx={{
                  cursor: getCursor(
                    item.additionalPropertiesParsed.TargetEmployeeId,
                    item.notificationType
                  ),
                }}
                onClick={() =>
                  handleNavigation(
                    item.additionalPropertiesParsed.Id,
                    item.additionalPropertiesParsed.TargetEmployeeId,
                    item.notificationType,
                    item.additionalPropertiesParsed.Header,
                    item.additionalPropertiesParsed.RequestType
                  )
                }
              >
                <Typography sx={{ fontSize: 13 }} gutterBottom>
                  {item.additionalPropertiesParsed.Header}
                </Typography>
                <Typography sx={{ fontSize: 11 }} color="text.primary">
                  {item.additionalPropertiesParsed.Message}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                {!item.isLoading && (
                  <Button
                    aria-label="Clear notification"
                    onClick={() => {
                      handleClearNotification(
                        item.id,
                        item.recipientId,
                        item.additionalPropertiesParsed.CallingEmployeeId || item.recipientId
                      )
                    }}
                  >
                    <CancelIcon color="primary" />
                  </Button>
                )}
                {item.isLoading && <CircularProgress />}
              </Grid>
            </Grid>
          </div>
        ))}
      {!isLoading && notificationList.length === 0 && (
        <div className="item itemlast empty">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paragraph>No Notifications</Paragraph>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

export default NotificationContent
